// Dom7
import { routes } from "./routes.js";
import {
  homeBot,
  addAction,
  newMessage,
  addmessage,
  saveValue,
  savePersistData,
  offline,
  clearproduct,
  nativeCarousel,
} from "./sr_x.js";

// import {Dom7} from "../dist/bundle";
// import {Framework7, Dom7} from 'framework7';
// import Framework7 from 'framework7/bundle';
import moment from "moment";
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
import firebase from "firebase/app";
import { FIREBASE } from "./firebase";
import welcomescreen from "f7-welcomescreen";
import Parse from "./db";
import "@lottiefiles/lottie-player";

var $$ = Dom7;
var brandID, brandName;

var welcomeScreenOptions = {
  bgcolor: "",
  fontcolor: "#212121",
};

var installPromptEvent;
window.addEventListener("beforeinstallprompt", (event) => {  
  installPromptEvent = event;  
});

var welcomescreen_slides = [
  {
    id: "slide0",
    // title: "Welcome/App intro slide", // optional
    picture:
      '<div align="center"><lottie-player src="img/phone.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "Welcome to <b>ChatApp</b>.",
  },
  {
    id: "slide1",
    title: "Follow <b>Creators</b>", // optional
    picture:
      '<div align="center"><lottie-player src="img/content-loyalty.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "For <b>Amazing</b> content.",
  },
  {
    id: "slide2",
    title: "Earn <b>Points</b> for Surfing", // optional
    picture:
      '<div align="center"><lottie-player src="img/gift.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "Redeem points for <b>Gifts</b> and more.",
  },
  {
    id: "slide3",
    title: "Enjoy <b>Contactless</b> Dining",
    picture:
      '<div align="center"><lottie-player src="img/scan-menu.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "Scan QR Menu and get <b>Special Offers</b>",
  },
  {
    id: "slide4",
    title: "Shop <b>Unique</b> products",
    picture:
      '<div align="center"><lottie-player src="img/shopping-bag.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "from specially <b>Curated</b> stores.",
  },
  {
    id: "slide5",
    title: "Get FREE <b>Contactless</b> Visiting Card",
    picture:
      '<div align="center"><lottie-player src="img/scan.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "never run out of <b>Cards</b> again",
  },
  {
    id: "slide6",
    title: "Become <b>Star</b> influencer",
    picture:
      '<div align="center"><lottie-player src="img/star.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text: "enjoy special <b>Privileges</b>",
  },
  {
    id: "slide6",
    title: "Join <b>Loyalty programs</b> in one touch",
    picture:
      '<div align="center"><lottie-player src="img/contactless.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop autoplay></lottie-player></div>',
    text:
      "<button class='button button-fill button-rasied button-round close-welcomescreen'>Get Started</button>",
  },
];

var code = jQuery(".botui-app-container").data("code");
var pdata = Parse.Object.extend("brandData");
var query = new Parse.Query(pdata);

code &&
  query.get(code).then((data) => {
    let json = JSON.parse(JSON.stringify(data));
    json = json.payload_json;
    brandID = json.brand_id;
    brandName = json.brand_name;
    console.log(`Brand name: ${brandName}`);
    localStorage.setItem(`brandName`, brandName);
    let introSlide = json.is_intro_slide;
    console.log(`Intro slide: ${introSlide}`);
    // if (introSlide) {
      var appIntroSlide = window.localStorage.getItem("INTRO_SLIDE");

      // !appIntroSlide && Framework7.use(welcomescreen);
      Framework7.use(welcomescreen);
    // }
  });

// Theme
var theme = "auto";
if (document.location.search.indexOf("theme=") >= 0) {
  theme = document.location.search.split("theme=")[1].split("&")[0];
}

const TEST_USER = Parse.User.current();
console.log(`Parse user is:`, TEST_USER);

/*Parse.User.requestPasswordReset("aman@socialrecharge.com")
.then(() => {
  // Password reset request was sent successfully
  console.log(`Password reset email send successfully.`);
}).catch((error) => {
  // Show the error message somewhere
  console.error("Error: " + error.code + " " + error.message);
});*/

// Init App
var app = new Framework7({
  id: "com.socialrecharge.chatbot",
  root: "#app",
  init: false,
  theme: theme,
  data: function () {
    return {
      user: {
        firstName: "Suman",
        lastName: "Kumar",
      },
    };
  },
  methods: {
    helloWorld: function () {
      app.dialog.alert("Hello World!");
    },
  },
  touch: {
    fastClicks: false,
  },
  view: {
    iosDynamicNavbar: true,
    xhrCache: false,
    pushState: true,
    stackPages: true,
  },
  photoBrowser: {
    type: "popup",
  },
  routes: routes,
  popup: {
    closeOnEscape: false,
    closeByBackdropClick: false,
  },
  sheet: {
    closeByBackdropClick: false,
  },
  dialog: {
    // set default title for all dialog shortcuts
    title: localStorage.getItem(`brandName`)
      ? localStorage.getItem(`brandName`)
      : "ChatApp.Store",
    // change default "OK" button text
    buttonOk: "Done",
  },
  popover: {
    closeOnEscape: true,
  },
  actions: {
    closeOnEscape: true,
    convertToPopover: false,
    grid: true,
  },
  vi: {
    placementId: "pltd4o7ibb9rc653x14",
  },
  welcomescreen: {
    // Setup welcomescreen plugin
    slides: welcomescreen_slides,
    options: welcomeScreenOptions,
  },
  serviceWorker: {
    path: "./firebase-messaging-sw.js",
    scope: "/",
  },
});

app.init();

console.log(`App online: ${app.online}`);
app.on('connection', (isOnline) => {
  console.log(`Inside connection isOnline callback.`);
  if (isOnline) {
    console.log('app is online now')
  } else {
    console.log('app is offline now')
  }
});

setTimeout(() => {
  console.log(`After 2500 MS:-`)
  console.log(`welcomescreen:`,welcomescreen);
  console.log(`welcomescreen.Welcomescreen:`,welcomescreen.Welcomescreen);
  console.log(`welcomescreen.params`, welcomescreen.params)
  console.log(`welcomescreen.install()`, welcomescreen.install())
  console.log(`welcomescreen.name`, welcomescreen.name)
  console.log(`welcomescreen.on`,welcomescreen.on)
  // welcomescreen.on(init => {

  // });
  // app.welcomescreen.open();
}, 2500)

/*$.ajax({
  type: 'POST',
  url: `https://5e60816f6d8b.ngrok.io/fetch/send`,
  headers: { "Content-Type": "application/json" },
  data: JSON.stringify(
    { 
      mobile: "+917777777777",
      title: "Title",
      body: "Notification body",
      sound: "res/raw/doorbell.mp3"
    }),
  success: (data) => {console.log(`Ajax was success.`,data)},
  error: (error) => {console.error(`Something went wrong. Error is:`,error)}
});*/

var mainView = app.views.create(".view-main");
app.sheet.create({
  el: ".my-sheet-swipe-to-close",
  swipeToClose: true,
  backdrop: true,
});
// Create swipe-to-step Sheet
app.sheet.create({
  el: ".my-sheet-swipe-to-step",
  swipeToClose: true,
  swipeToStep: true,
  backdrop: true,
});

// var homeBot = new BotUI("hello-world");

let updateUserProfile = () => {
  let name =
    localStorage.getItem(`brandid`) &&
    (localStorage.getItem(`name`) ||
      localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
      localStorage.getItem(`userdata-${localStorage.getItem(`brandid`)}-name`))
      ? localStorage.getItem(`name`) ||
        localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
        localStorage.getItem(`userdata-${localStorage.getItem(`brandid`)}-name`)
      : null;
  
  if (name) {
    const user = FIREBASE.auth().currentUser;
    if (user) {      
      user
      .updateProfile({
        displayName: name,
        photoURL: ``,
      })
      .then(function () {
        // Update successful.
        // console.log(`User profile updated successfully.`)
      })
      .catch(function (error) {
        // An error happened.
        console.error(
          `Error in updating user profile. Error is ${JSON.stringify(error)}`
        );
      });
    }
  }
};

let afterUserVerification = (data) => {
  try {    
    let { name, userID } = data;    
    var newmsg = [
      {
        add: {
          content: `Hey ${
            name ? name : `friend`
          }, is this, ${userID}, your number? Just click button below and you are verified.`,
        },
      },
      {
        action: {
          button: {
            action: [
              { text: userID, value: `associateUserWithBrand_${userID}` },
            ],
          },
        },
        save: "mobile",
      },
    ];
    addmessage(newmsg);
  } catch (error) {
    console.error(
      `Error in afterUserVerification module. Error is ${JSON.stringify(error)}`
    );
  }
};

async function authenticateUser(messages) {
  try {
    let isSignedIn = localStorage.getItem(`isSignedIn`);
    let userID = localStorage.getItem(`vmn`);
    let name = localStorage.getItem(`name`);
    if (isSignedIn && userID) {
      addmessage(messages);
    } else {
      let name;
      const user = await FIREBASE.auth().currentUser;
      if (user) {
        // console.log("USER: ", JSON.stringify(user));
        (await user.displayName) != null
          ? (name = user.displayName.trim())
          : null;
        (await user.displayName) == null &&
        localStorage.getItem(`brandid`) &&
        (localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
          localStorage.getItem(
            `userdata-${localStorage.getItem(`brandid`)}-name`
          ))
          ? updateUserProfile()
          : `${
              ((name = user.displayName.trim()),
              ((localStorage.getItem(`brandid`) || brandID) &&
                localStorage.setItem(
                  `${localStorage.getItem(`brandid`) || brandID}-name`,
                  user.displayName
                )) ||
                ((localStorage.getItem(`brandid`) || brandID) &&
                  localStorage.setItem(
                    `userdata-${
                      localStorage.getItem(`brandid`) || brandID
                    }-name`,
                    user.displayName
                  )))
            }`;
        (await user.phoneNumber)
          ? localStorage.setItem("vmn", user.phoneNumber)
          : null;
        //DEAL WITH FOLLOWUP FUNCTIONALITY, TAKING USER CONSENT ABOUT USER ID
        let data = {
          name: user.displayName ? user.displayName : `friend`,
          userID: user.phoneNumber,
        };
        // afterUserVerification(data);
        addmessage(messages);
      } else {
        offline("selectcountry");
      }
    }
  } catch (err) {
    console.error(
      `Error in authenticateUser module. Error is ${JSON.stringify(error)}`
    );
  }
}

function cancelReservation(objId, brand, mobile) {
  // console.log(objId,brand,mobile);
  app.dialog
    .create({
      text: "Are you sure you want to cancel this reservation?",
      buttons: [
        {
          text: "No",
        },
        {
          text: "Yes",
          onClick: function () {
            jQuery.ajax({
              url:
                "https://chatappapi.server.scvpl.in/update/user/reservation/",
              type: "POST",
              data: {
                brandid: brand,
                objectID: objId,
                mobile: mobile,
                status: "Canceled",
              },
              success: function (data) {
                // console.log(data);
                if (data.error) {
                  app.dialog.alert(data.error);
                } else {
                  jQuery("#stat_" + objId).html(data.status);
                  jQuery("#button_" + objId).hide();
                  if (!data.objectId) {
                    if (data[0].messages[0]) {
                      // console.log(data[0].messages[0]);
                      mainView.router.back();
                      newMessage(data[0].messages[0]);
                    }
                  }
                }
              },
            });
          },
        },
      ],
    })
    .open();
}

async function verifyUser() {
  try {
    let isSignedIn = localStorage.getItem(`isSignedIn`);
    let userID = localStorage.getItem(`vmn`);
    let name = localStorage.getItem(`name`);
    if (isSignedIn && userID) {
      // console.log(`User is authenticated with the id ${userID}.`);
      //DEAL WITH FOLLOWUP FUNCTIONALITY, TAKING USER CONSENT ABOUT USER ID
      let data = { name: name, userID: userID };
      afterUserVerification(data);
    } else {            
      const user = await FIREBASE.auth().currentUser;      
      if (user) {
        (await user.displayName) != null
          ? (name = user.displayName.trim())
          : null;
        (await user.displayName) == null &&
        localStorage.getItem(`brandid`) &&
        (localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
          localStorage.getItem(
            `userdata-${localStorage.getItem(`brandid`)}-name`
          ))
          ? updateUserProfile()
          : `${
              ((name = user.displayName.trim()),
              ((localStorage.getItem(`brandid`) || brandID) &&
                localStorage.setItem(
                  `${localStorage.getItem(`brandid`) || brandID}-name`,
                  user.displayName
                )) ||
                ((localStorage.getItem(`brandid`) || brandID) &&
                  localStorage.setItem(
                    `userdata-${
                      localStorage.getItem(`brandid`) || brandID
                    }-name`,
                    user.displayName
                  )))
            }`;
        (await user.phoneNumber)
          ? localStorage.setItem("vmn", user.phoneNumber)
          : null;
        //DEAL WITH FOLLOWUP FUNCTIONALITY, TAKING USER CONSENT ABOUT USER ID
        let data = {
          name: user.displayName ? user.displayName : `friend`,
          userID: user.phoneNumber,
        };
        afterUserVerification(data);
      } else {        
        offline("selectcountry", "") || selectcountry();
      }
    }
  } catch (error) {
    console.error(
      `Error in verifyUser module. Error is ${JSON.stringify(error)}`,
      error
    );
  }
}

async function takeUserConsent(data, messages) {
  try {
    let { name, userID } = data;
    var newmsg = [
      {
        add: {
          content: `Hey ${
            name ? name : `friend`
          }, your verified number is ${userID}. Click your number to continue, or click "No" to use another number.`,
        },
      },
      {
        action: {
          button: {
            action: [
              {
                text: userID,
                value: `saveAndAssociateUserWithBrand_${userID}`,
              },
              messages[0].action.button.action[0],
            ],
          },
        },
        save: "mobile",
      },
    ];
    addmessage(newmsg);
  } catch (error) {
    console.error(
      `Error in takeUserConsent module. Error is ${JSON.stringify(error)}`
    );
  }
}

async function saveUserAndBrand(data) {
  //SET BRAND, AS USER VERIFIED WITH THIS BRAND
  let brand_id = localStorage.getItem(`brandid`);
  let visitedPage = localStorage.getItem("visitedPage");
  if (visitedPage && visitedPage.includes(brand_id)) {
  } else {
    visitedPage = visitedPage ? visitedPage.split(",") : [];
    visitedPage.push(brand_id);
    localStorage.setItem("visitedPage", visitedPage.toString());
  }
}

async function checkUserAuth(messages, triggerBack) {
  triggerBack
    ? saveValue(`triggerBack`, triggerBack)
    : saveValue(`action`, `feedback`);
  const altMessages = [
    {
      action: {
        button: {
          action: [
            {
              text: "No",
              value: "selectcountry",
            },
          ],
        },
      },
    },
  ];
  let visitedPage = localStorage.getItem("visitedPage");
  let brand_id = localStorage.getItem(`brandid`);
  let isSignedIn = localStorage.getItem(`isSignedIn`);
  let userID = localStorage.getItem(`vmn`);

  visitedPage && visitedPage.includes(brand_id) && isSignedIn === `true` && userID
    ? addmessage(messages)
    : verifyBotUser(altMessages);
}

// async function isUserVerifiedWithPage(messages) {
//   let userID = localStorage.getItem(`vmn`);
//   let userRef = new Parse.Query(`ChatAppUsers`);
//   userRef.equalTo(`mobile`, userID);
//   let results = await userRef.find();
//   userRef.id = results[0].id;
//   let userObj = new Parse.Object(`ChatAppUsers`);
//   userObj.id = results[0].id;
//   let relation = userObj.relation(`brands`);
//   let brandQuery = relation.query();
//   let brands = [];
//   await brandQuery.each((el) => brands.push(el.get(`brand_code`)));
//   if (brands.includes(localStorage.getItem(`brand_code`))) {
//     addmessage(messages);
//   } else {
//     offline("get_started");
//   }
// }

async function isUserVerifiedWithPage(messages) {
  let userID = localStorage.getItem(`vmn`);
  let userRef = new Parse.Query(`ChatAppUsers`);
  userRef.equalTo(`mobile`, userID);
  let results = await userRef.find();
  userRef.id = results[0].id;
  let userObj = new Parse.Object(`ChatAppUsers`);
  userObj.id = results[0].id;
  let relation = userObj.relation(`brands`);
  let brandQuery = relation.query();
  let brands = [];
  await brandQuery.each((el) => brands.push(el.get(`brand_id`)));
  if (brands.includes(localStorage.getItem(`brandid`))) {
    addmessage(messages);
  } else {
    offline("get_started");
  }
}

function payviaupi(params) {
  // console.log(navigator.userAgent);
  let nextPayload = params.nextpaylod;
  let upiLink = encodeURIComponent(params.upilink);
  // console.log(upiLink);
  if (app.device.desktop || app.device.macos) {
    var newmsg = [
      {
        add: {
          content: `Scan qrcode and enter the bill amount to make payment:`,
        },
      },
      {
        add: {
          type: "html",
          content:
            `<img class='botui-message-content-image' src='http://chart.apis.google.com/chart?cht=qr&chl=` +
            upiLink +
            `&chs=248' alt='UPI Pay'>`,
        },
      },
      {
        action: {
          button: {
            action: [
              {
                text: "☰ Explore",
                value: "explore",
              },
            ],
          },
        },
        delay: 1000,
      },
    ];
    addmessage(newmsg);
  } else {
    offline(nextPayload);
  }
}

function addtohome() {
  //console.log("addtohome");
  
  if (navigator.userAgent.match(/iPad|webOS|iPhone|iPod|Blackberry/i)) {
    var newmsg = [
      {
        add: {
          type: "html",
          content: `<img class='botui-message-content-image' src='https://firebasestorage.googleapis.com/v0/b/project-1956585320571671692.appspot.com/o/chatbots%2Falgincafeamritsar%2Faddtohome.jpg?alt=media&token=3cb7269c-a0d5-414a-b7a4-3255018a077d' alt='Add to Home'>`,
        },
      },
      {
        action: {
          button: {
            action: [
              {
                text: "☰ Explore",
                value: "explore",
              },
            ],
          },
        },
        delay: 1000,
      },
    ];
    addmessage(newmsg);
  } else {      
    var newmsg = [
      {
        add: {
          content: `Thank you for adding me to your home. You can tap on the button below to explore more:`,
        },
      },
      {
        action: {
          button: {
            action: [
              {
                text: "☰ Explore",
                value: "explore",
              },
            ],
          },
        },
        delay: 1000,
      },
    ];
    addmessage(newmsg);
    
    const pwaModeEnabled =
      window.navigator.standAlone || // Safari
      window.fullScreen || // FireFox
      (!window.screenTop && !window.screenY); // Chrome
    
    if (!pwaModeEnabled) {      
    } else {            
        installPromptEvent && installPromptEvent.prompt();
        // Wait for the user to respond to the prompt
        installPromptEvent &&
          installPromptEvent.userChoice.then((choice) => {
            // console.log(choice);
            if (choice.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");              
            } else {
              console.log("User dismissed the A2HS prompt");
            }
            // Clear the saved prompt since it can't be used again
            installPromptEvent = null;
          });
        
        //Will FIRE THIS EVENT IF PWA INSTALLED VIA A2H BUTTON OR ADDRESS BAR PROMPT OR ANY OTHER PLACES
        /*window.addEventListener('appinstalled', (event) => { 
          event.preventDefault();
          installPromptEvent = null;
          // Optionally, send analytics event to indicate successful install
          console.log('PWA was installed');
          var newmsg = [
            {
              add: {
                content: `Thank you for adding me to your home. You can tap on the button below to explore more:`,
              },
            },
            {
              action: {
                button: {
                  action: [
                    {
                      text: "☰ Explore",
                      value: "explore",
                    },
                  ],
                },
              },
              delay: 1000,
            },
          ];
          addmessage(newmsg);
        });*/
      
    }
  }
}

function selectcountry() {
  var countryObj = Parse.Object.extend("permanent_jsons");
  var countryQuery = new Parse.Query(countryObj);
  countryQuery.get("AU0vL0a5DG").then((data) => {    
    let countryDialCode = data.get("countries").map((country) => {
      return {
        text: country.name,
        value: country.dial_code,
      };
    });

    homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `Please select your country, where your mobile number is registered.`,
      })
      .then(() => {
        homeBot.action
          .select({
            action: {
              nextpayload: "mobverify",
              placeholder: "Please select your country",
              value: "+91", // Selected value or selected object. Example: {value: "TR", text : "Türkçe" }
              searchselect: false, // Default: true, false for standart dropdown
              label: "text", // dropdown label variable
              options: countryDialCode,
              button: {
                icon: "check",
                label: "OK",
                value: "mobverify",
              },
            },
          })
          .then(function (res) {
            // will be called when a button is clicked.
            // console.log(`Selected value is ${res.value}`);
            localStorage.setItem(`countryCode`, res.value);
            homeBot.message
              .add({
                delay: 1000,
                loading: true,
                content: `Please enter your mobile number`,
              })
              .then((res) => {
                let action = {
                  action: {
                    text: {
                      action: {
                        nextpayload: "phoneinput",
                        placeholder: "Enter mobile number here",
                        sub_type: "number",
                      },
                    },
                  },
                  save: "mobunv",
                };
                addAction(action);
              });
          });
      });
  });
}

function verifymob() {
  // console.log(`Inside verifymob function.`);
  var brandID = localStorage.getItem("brandid");
  let countryCode = localStorage.getItem(`countryCode`);
  var val = localStorage.getItem("userdata-" + brandID + "-mobunv");

  var number = countryCode + val;
  var phone = number.split("+");

  var recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    "recaptcha-container",
    { size: "invisible" }
  );
  var reminder = parseInt(phone[1]);
  reminder = phone[1] % 2;
  if (phone[1] && phone[1].length >= 10 && (reminder == 0 || reminder == 1)) {
    var appVerifier = recaptchaVerifier;

    FIREBASE.auth()
      .signInWithPhoneNumber(number, appVerifier)
      .then((confirmationResult) => {
        // pass verificationId to signInWithVerificationId
        var verificationId = confirmationResult.verificationId;

        homeBot.message.add({
          content:
            "Please enter 6 digit OTP sent via SMS to verify your number.",
        });
        homeBot.action
          .text({
            action: {
              placeholder: "Enter 6 digit OTP received on SMS",
              sub_type: "number",
            },
          })
          .then(function (res) {
            var credential = firebase.auth.PhoneAuthProvider.credential(
              verificationId,
              res.value
            );

            if (credential) {
              FIREBASE.auth()
                .signInWithCredential(credential)
                .then(async (user) => {
                  var phoneno = number || user.user.phoneNumber;

                  const query = new Parse.Query(Parse.User);
                  query.equalTo("phone", phoneno);
                  const userData = await query.first();
                  if (userData) { //USER DATA IS AVAILABLE, GO FOR USER LOGIN OPERATION                    
                    const loggedInUser = await Parse.User.logIn(userData.get(`username`), user.user.uid);                    
                  } else {  //GO FOR USER SIGNUP                    
                    try {                      
                      const AUTH_DATA = {
                        authData: {
                          "access_token": await user.user.getIdToken(),
                          "id": user.user.uid
                        },
                      };
  
                      const provider = {
                        authenticate: (options) => {
                          options.success(this, {
                            id: user.user.uid
                          });                        
                        },
                        restoreAuthentication() {
                          return true;
                        },
  
                        getAuthType() {
                          return 'firebase';
                        },
  
                        getAuthData() {
                          return {
                            authData: {
                              "access_token": user.user.getIdToken(),
                              "id": user.user.uid
                            },
                          };
                        },
                      };
  
                      Parse.User._registerAuthenticationProvider(provider);     
                      const parseUser = new Parse.User();
                      parseUser.setUsername(phoneno); //FETCH USERNAME FROM LOCAL STORAGE or DB
                      parseUser.setPassword(user.user.uid); //ALSO ASSIGN HERE
                      parseUser.set(`phone`, user.user.phoneNumber || number);
                      await parseUser.signUp();
                      await parseUser.linkWith('firebase', AUTH_DATA);
                      parseUser._isLinked('firebase');                      
                      await Parse.Cloud.run('assignRole');                                       
                    }catch(error){
                      console.error(`Error in firebase auth:`, error);
                    }
                  }
                  
                  var mobile = phoneno.split("+");
                  window.localStorage.setItem(
                    "firebase:authUser:AIzaSyAChAejJAD0N8mUebbr7Xw0v-A9KwagUaQ:[DEFAULT]",
                    JSON.stringify(user)
                  );
                  // window.localStorage.setItem("vmn", mobile[1]);
                  let name =
                    localStorage.getItem(`brandid`) &&
                    (localStorage.getItem(
                      `${localStorage.getItem(`brandid`)}-name`
                    ) ||
                      localStorage.getItem(
                        `userdata-${localStorage.getItem(`brandid`)}-name`
                      ))
                      ? localStorage.getItem(
                          `${localStorage.getItem(`brandid`)}-name`
                        ) ||
                        localStorage.getItem(
                          `userdata-${localStorage.getItem(`brandid`)}-name`
                        )
                      : null;
                  localStorage.setItem("vmn", number);
                  saveValue("mobile", number);
                  savePersistData("mobile", number);

                  //SET BRAND, AS USER VERIFIED WITH THIS BRAND
                  let brand_id = localStorage.getItem(`brandid`);
                  let visitedPage = localStorage.getItem("visitedPage");
                  visitedPage = visitedPage ? visitedPage.split(",") : [];
                  visitedPage.push(brand_id);
                  localStorage.setItem("visitedPage", visitedPage.toString());

                  var newmsg = [
                    {
                      add: {
                        content: `Hey ${
                          name ? name : `friend`
                        }, your mobile number ${number} is verified now.`,
                      },
                    },
                  ];
                  addmessage(newmsg);
                  /******** FOR FB PAGE AUTH, ONLY */
                  const pageType = jQuery(".botui-app-container").data(
                    "pagetype"
                  );
                  const fbPageID = jQuery(".botui-app-container").data(
                    "pageid"
                  );
                  brandID = jQuery(".botui-app-container").data("brandid");
                  if (pageType === "fb" && fbPageID) {
                    saveValue("pageType", "fb");
                  }
                  /******** END FB PAGE AUTH */
                  jQuery("#recaptcha-container").hide();
                  offline("completedBasicProfile");

                  recaptchaVerifier.clear();
                  $$(`.gRecaptcha`).html(``);
                  $$(`.gRecaptcha`).html(
                    `<div id="recaptcha-container" style="padding:5px 25px;"></div>`
                  );
                })
                .catch(function (error) {
                  recaptchaVerifier.clear();
                  $$(`.gRecaptcha`).html(``);
                  $$(`.gRecaptcha`).html(
                    `<div id="recaptcha-container" style="padding:5px 25px;"></div>`
                  );
                  // Handle Errors here.

                  var errorCode = error.code;
                  console.error(`Error code:${errorCode}`);
                  console.error(`Error message: ${error.message}`);
                  let errorMessage = ``;
                  if (
                    errorCode ===
                    "auth/account-exists-with-different-credential"
                  ) {
                    console.error(
                      "Email already associated with another account."
                    );
                    errorMessage = `Please check if you entered correct OTP or try again`;
                    // Handle account linking here, if using.
                  } else if (errorCode === `auth/invalid-credential`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/operation-not-allowed`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/user-disabled`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/user-not-found`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/wrong-password`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/invalid-verification-code`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  } else if (errorCode === `auth/invalid-verification-id`) {
                    errorMessage = `Please check if you entered correct OTP or try again`;
                  }
                  homeBot.message.add({
                    content: errorMessage,
                  });
                  verifymob();
                });
            } else {
              recaptchaVerifier.clear();
              $$(`.gRecaptcha`).html(``);
              $$(`.gRecaptcha`).html(
                `<div id="recaptcha-container" style="padding:5px 25px;"></div>`
              );
              console.error("Failed to sign in", error);
              var newmsg = [
                {
                  add: {
                    content: error,
                  },
                },
              ];
              addmessage(newmsg);
              selectcountry();
            }
          });
      })
      .catch(function (error) {
        console.log("error", error);
        recaptchaVerifier.clear();
        $$(`.gRecaptcha`).html(``);
        $$(`.gRecaptcha`).html(
          `<div id="recaptcha-container" style="padding:5px 25px;"></div>`
        );
        var newmsg = [
          {
            add: {
              content: error.message,
            },
          },
        ];
        addmessage(newmsg);
        selectcountry();
      });
  } else {
    recaptchaVerifier.clear();
    $$(`.gRecaptcha`).html(``);
    $$(`.gRecaptcha`).html(
      `<div id="recaptcha-container" style="padding:5px 25px;"></div>`
    );
    console.error("Please enter a valid, ten digit long, mobile number.");
    var newmsg = [
      {
        add: {
          content: "Please enter a valid, ten digit long, mobile number.",
        },
      },
      {
        action: {
          button: {
            action: [
              {
                text: "Try Again",
                value: "selectcountry",
              },
            ],
          },
        },
        delay: 1000,
      },
    ];
    addmessage(newmsg);
  }
}

function verifyemail() {
  var val = localStorage.getItem("userdata-" + brandID + "-gmail");
  // console.log("verifyemail: " + val);
  var clientId =
    "194244686544-e8ebquu5c3n0l1l4s7j5g03urvt94thh.apps.googleusercontent.com";
  FirebasePlugin.authenticateUserWithGoogle(
    clientId,
    function (credential) {
      FirebasePlugin.signInWithCredential(
        credential,
        function () {
          FirebasePlugin.reloadCurrentUser(
            function (user) {
              // console.log("Name: " + user.name);
              // console.log("Email: " + user.email);
              // console.log("Is email verified?: " + user.emailIsVerified);
              // console.log("Phone number: " + user.phoneNumber);
              // console.log("Photo URL: " + user.photoUrl);
              // console.log("UID: " + user.uid);
              // console.log("Provider ID: " + user.providerId);
              // console.log("ID token: " + user.idToken);
              saveValue("gmail", user.email);
              savePersistData("gmail", user.email);
              var newmsg = [
                {
                  add: {
                    content: `You are now verified your email <b>${
                      user.email ? user.email : ""
                    }</b> also.`,
                  },
                },
              ];
              addmessage(newmsg);
              offline("postEmailVerification");
            },
            function (error) {
              console.error("Failed to get current user data: " + error);
            }
          );
        },
        function (error) {
          console.error("Failed to sign in", error);
          var newmsg = [
            {
              add: {
                content: `Sorry, email verification failed, please try again to complete your profile.`,
              },
            },
            {
              action: {
                button: {
                  action: [
                    {
                      text: "Try Again",
                      value: "emailverify",
                    },
                  ],
                },
              },
              delay: 1000,
            },
          ];
          addmessage(newmsg);
        }
      );
    },
    function (error) {
      console.error("Failed to authenticate with Google: " + error);
      var newmsg = [
        {
          add: {
            content: "Failed to authenticate with Google: " + error,
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: "Try Agian",
                  value: "emailverify",
                },
              ],
            },
          },
          delay: 1000,
        },
      ];
      addmessage(newmsg);
    }
  );
}

function onBodyLoad() {
  document.addEventListener("backbutton", onBackKeyDown, false);
  jQuery(".app").removeClass("framework7-root");
}

function onBackKeyDown() {
  var cpage = mainView.router.currentRoute;
  // console.log(cpage);
  // console.log(app.popup.get('.popup-category'));
  if (app.sheet.get(".sheet-modal").open) {
    app.sheet.close();
  }
  if (cpage.url == "/" || cpage.url == "/android_asset/www/index.html") {
    app.dialog.confirm("Are you sure you want to exit", function () {
      var deviceType = device.platform;
      // console.log(deviceType);
      if (deviceType == "Android" || deviceType == "android") {
        navigator.app.exitApp();
      }
    });
  } else if (cpage.name == "cart") {
    mainView.router.back();
    var path = cpage.path.split("cart/");
    // mainView.router.navigate({ name: "listapp", params: { brandid: path[1] } });
    mainView.router.navigate("listapp/" + path[1], {
      reloadCurrent: true,
      ignoreCache: true,
    });
    app.sheet.open(".products-swipe-to-step");
  } else if (cpage.name == "listapp") {
    openIndexPage("products");
  } else if (cpage.name == "orders") {
    ordersBack();
  } else if (app.popup.get(".popup-category")) {
    // console.log(app.popup.get(".popup-category").opened);
    if (app.popup.get(".popup-category").opened == true) {
      app.popup.close();
    } else {
      mainView.router.back();
    }
  } else {
    mainView.router.back();
  }
}

function fetchUserLocationWeather(latLong) {
  let message = {
    add: {
      content: `Thank you, we got your location.`,
    },
  };
  newMessage(message);
  let location = latLong.split(",");
  let lat = location[0];
  let long = location[1];
  app.request.promise
    .json("https://parseapi.server.scvpl.in/weather/forecast", {
      lat: lat,
      long: long,
    })
    .then((res) => {
      // console.log(res.data);
      message = [
        {
          add: {
            content: res.data.message,
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: "📍 Send Location",
                  value: "getlocation",
                },
                {
                  type: "postback",
                  text: "Change Location",
                  value: "changeLocation",
                },
              ],
            },
          },
          save: "weatherLocation",
        },
      ];
      addmessage(message);
    })
    .catch((err) => {
      console.error(err.xhr);
      console.error(err.status);
      console.error(err.message);
    });
}

function fetchTodayHoroscope(sign) {
  // console.log(`User sign is ${sign}`);
  let message = {
    add: {
      content: `Thank you, we got your zodiac sign.`,
    },
  };
  newMessage(message);

  app.request.promise
    .json("https://parseapi.server.scvpl.in/get/horoscope/today", {
      sign: sign,
    })
    .then((res) => {
      // console.log(res.data);

      message = {
        add: {
          content: res.data.message,
        },
      };
      newMessage(message);
    })
    .catch((err) => {
      console.error(err.xhr);
      console.error(err.status);
      console.error(err.message);
    });
}

async function verifyBotUser(messages) {  
  let userID = localStorage.getItem(`vmn`);
  let name = localStorage.getItem(`name`);
  if (userID && name) {
    const data = { name: name, userID: userID };
    takeUserConsent(data, messages);
  } else {    
    const user = await FIREBASE.auth().currentUser;    
    if (user) {
      (await user.displayName) != null
        ? (name = user.displayName.trim())
        : null;
      (await user.displayName) == null &&
      localStorage.getItem(`brandid`) &&
      (localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
        localStorage.getItem(
          `userdata-${localStorage.getItem(`brandid`)}-name`
        ))
        ? updateUserProfile()
        : `${
            ((name = user.displayName.trim()),
            ((localStorage.getItem(`brandid`) || brandID) &&
              localStorage.setItem(
                `${localStorage.getItem(`brandid`) || brandID}-name`,
                user.displayName
              )) ||
              ((localStorage.getItem(`brandid`) || brandID) &&
                localStorage.setItem(
                  `userdata-${localStorage.getItem(`brandid`) || brandID}-name`,
                  user.displayName
                )))
          }`;
      (await user.phoneNumber)
        ? localStorage.setItem("vmn", user.phoneNumber)
        : null;
      //DEAL WITH FOLLOWUP FUNCTIONALITY, TAKING USER CONSENT ABOUT USER ID
      let data = {
        name: user.displayName ? user.displayName : `friend`,
        userID: user.phoneNumber,
      };
      takeUserConsent(data, messages);
    } else {
      offline("selectcountry", "") || selectcountry();
    }
  }  
}

function openUsername(params) {
  // console.log(params);
  let type = params.sub_type;
  let save = params.save;
  let validate = params.validate;
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content:
        params.legend +
        `<small style="color:green;"> Note: min ${params.data.min} chars and max ${params.data.max} chars.</small>`,
    })
    .then(function (index) {
      let htmlElement = `<input type=${type} id=${params.data.id} placeholder=${params.placeholder} required onkeyup="successUser(this)" class="botui-actions-text-input ${params.data.id}"> <button type="submit" id='submit${params.data.id}' onclick="checkAvailability('${params.data.id}', '${params.nextpayload}', '${save}', '${validate}')" style="position:relative;top:-7px;" class="botui-actions-buttons-button"><span>Go</span></button><br><small class='${params.data.id}_msg'></small>`;
      var result = {
        add: {
          type: "html",
          content: htmlElement,
        },
      };
      newMessage(result);
    });
}

function successUser(id) {
  var value = id.getAttribute("id");
  var val = id.value;
  // var val = jQuery("."+id).val();
  // console.log(val);
  var pattern = /^[0-9a-zA-Z]+$/;
  if (val.trim() == "") {
    jQuery(".username_msg")
      .html("Please type username to proceed!")
      .css("color", "red");
    document.getElementById(`submitusername`).disabled = true;
  } else if (val.length < 5) {
    jQuery(".username_msg")
      .html("Please enter a minimum of 5 characters!")
      .css("color", "red");
    document.getElementById(`submitusername`).disabled = true;
  } else if (val.length > 20) {
    jQuery(".username_msg")
      .html("Please enter a maximum of 20 characters!")
      .css("color", "red");
    document.getElementById(`submitusername`).disabled = true;
  } else if (val.match(pattern)) {
    jQuery(".username_msg")
      .html(
        '<i class="fa fa-check" style="margin-left:10px;font-size:18px;"></i>'
      )
      .css("color", "green");
    document.getElementById(`submitusername`).disabled = false;
  } else {
    jQuery(".username_msg")
      .html("Please enter alphanumeric characters only")
      .css("color", "red");
    document.getElementById(`submitusername`).disabled = true;
  }
}

function checkAvailability(id, payload, save, validate) {
  // console.log(id, save, validate);
  let userValue = jQuery("." + id).val();
  // console.log("checkAvailability", userValue);
  var brand = localStorage.getItem(`brandid`);
  var mobile = localStorage.getItem(`vmn`);
  jQuery.ajax({
    url: "https://chatappapi.server.scvpl.in/check/username",
    type: "POST",
    data: { brandid: brand, mobile: mobile, userName: userValue },
    success: function (data) {
      // console.log(data[0]);
      jQuery(".username").removeClass("username");
      jQuery("#submitusername").removeAttr("id");
      jQuery(".username_msg").removeAttr("class");
      if (data[0].messages[0]) {
        newMessage(data[0].messages[0]);
      }
      if (data[0].messages[1]) {
        addAction(data[0].messages[1]);
      }
      if (data[0].success == true) {
        offline(payload);
      }
    },
  });
  if (save) saveValue(save, userValue);
}

function openDatePicker(params) {
  // console.log(`Date and time picker: ${JSON.stringify(params)}`);
  let type = params.sub_type;
  let nextPayload = params.nextpaylod;
  let persist = params.persist;
  let save = params.save;

  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: params.legend,
    })
    .then(function (index) {
      // let htmlElement = `<input type=${type} value='${min}' id=${params.date.id} min='${min}' max='${max}' required onchange="success('${params.date.id}')" class="botui-actions-text-input"> <button type="submit" id='submit${params.date.id}' onclick="getRangeValue('${params.date.id}', '${nextPayload}', '${persist}', '${save}', '${validate}', '${minTime}', '${maxTime}')" style="position: relative; top: -7px;" class="botui-actions-buttons-button"><span>OK</span></button>`;
      var fdate = new Date();
      fdate.setDate(fdate.getDate() + 1);
      var fromdate = new Date();
      fromdate.setDate(fromdate.getDate() + 2);
      var todate = new Date();
      if (params.days <= 30) {
        todate.setDate(todate.getDate() + parseInt(params.days));
      } else {
        todate.setDate(todate.getDate() + 30);
      }
      // console.log(todate);
      var htmlElement =
        `<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper dateswiper"><button class="dateui" onClick="selectDate('` +
        moment().format("YYYY-MM-DD") +
        `', '` +
        save +
        `', '` +
        nextPayload +
        `');"> Today <br> ` +
        moment().format("DD MMM") +
        `</button><button class="dateui" onClick="selectDate('` +
        moment(fdate).format("YYYY-MM-DD") +
        `', '` +
        save +
        `', '` +
        nextPayload +
        `');"> Tomorrow <br> ` +
        moment(fdate).format("DD MMM") +
        `</button>`;
      for (var d = fromdate; d <= todate; d.setDate(d.getDate() + 1)) {
        htmlElement +=
          `<button class="dateui" onClick="selectDate('` +
          moment(d).format("YYYY-MM-DD") +
          `', '` +
          save +
          `', '` +
          nextPayload +
          `');"> ` +
          moment(d).format("dddd") +
          ` <br> ` +
          moment(d).format("DD MMM") +
          `</button>`;
      }
      htmlElement += `</div></div>`;

      var result = {
        add: {
          type: "html",
          content: htmlElement,
        },
      };
      newMessage(result);
    });
}

jQuery(".dateui").click(function () {
  jQuery(this).addClass("active");
});

function selectDate(dateValue, save, nextPayload) {
  // console.log(dateValue);
  if (save) saveValue(save, dateValue);
  // homeBot.message.remove();
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: `You have selected ${dateValue}.`,
    })
    .then(function (index) {
      offline(nextPayload, dateValue);
    });
}

function openTimePicker(params) {
  // console.log(`Time picker: ${JSON.stringify(params)}`);
  let today = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1 < 10
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1
  }-${
    new Date().getDate() < 10
      ? "0" + new Date().getDate()
      : new Date().getDate()
  }T${params.time ? params.time.min : ""}`;
  // console.log(`today: `, today);

  const min =
    (params.date && params.date.min !== "") ||
    (params.time && params.time.min !== "")
      ? today
      : "";
  const max =
    (params.date && params.date.max !== "") ||
    (params.time && params.time.max !== "")
      ? `${params.date && params.date.max}T${params.time && params.time.max}`
      : "";
  let type = params.sub_type;
  let nextPayload = params.nextpaylod;
  let persist = params.persist;
  let save = params.save;
  let evalMinTime = params.time && params.time.min.split(":");
  let evalMaxTime = params.time && params.time.max.split(":");
  let minTime = params.time && params.time.min ? params.time.min : "";
  let maxTime = params.time && params.time.max ? params.time.max : "";
  let validate = params.validate;

  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: params.legend,
    })
    .then(function (index) {
      var quarterHours = ["00", "30"];
      var htmlElement =
        '<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper timeswiper">';
      var bookdate = localStorage.getItem(`userdata-${brandID}-bookdate`);
      var todayDate = moment().format("YYYY-MM-DD");
      // console.log(bookdate+" = "+todayDate);
      var startTime =
        bookdate == todayDate
          ? parseInt(moment().format("H")) + 1
          : evalMinTime[0];

      for (var i = startTime; i <= evalMaxTime[0]; i++) {
        for (var j = 0; j < 2; j++) {
          if (parseInt(i) !== parseInt(evalMaxTime[0])) {
            var dt = i + ":" + quarterHours[j];
            var time = moment(dt, ["h:mm"]).format("h:mm A");
            var timeVal = moment(dt, ["h:mm"]).format("HH:mm");
            htmlElement +=
              `<button class="timeui" onClick="selectTime('` +
              timeVal +
              `', '` +
              save +
              `', '` +
              nextPayload +
              `');">` +
              time +
              `</button>`;
          } else if (
            parseInt(i) === parseInt(evalMaxTime[0]) &&
            parseInt(evalMaxTime[1]) >= parseInt(quarterHours[j])
          ) {
            var dt = i + ":" + quarterHours[j];
            var time = moment(dt, ["h:mm"]).format("h:mm A");
            var timeVal = moment(dt, ["h:mm"]).format("HH:mm");
            htmlElement +=
              `<button class="timeui" onClick="selectTime('` +
              timeVal +
              `', '` +
              save +
              `', '` +
              nextPayload +
              `');">` +
              time +
              `</button>`;
          }
        }
      }
      htmlElement += `</div></div>`;

      var result = {
        add: {
          type: "html",
          content: htmlElement,
        },
      };
      newMessage(result);
    });
}

jQuery(".timeui").click(function () {
  jQuery(this).addClass("active");
});

function selectTime(timeValue, save, nextPayload) {
  // console.log(timeValue);
  var dateTimeValue =
    localStorage.getItem(`userdata-${brandID}-bookdate`) + "T" + timeValue;
  // console.log(dateTimeValue);
  saveValue("bookingtime", timeValue);
  if (save) saveValue(save, dateTimeValue);
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: `You have selected ${timeValue}.`,
    })
    .then(function (index) {
      offline(nextPayload, dateTimeValue);
    });
}

function openGuestPicker(params) {
  // console.log(`Guest picker: ${JSON.stringify(params)}`);
  let type = params.sub_type;
  let nextPayload = params.nextpaylod;
  let save = params.save;
  let totalguest = params.totalguest;

  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: params.legend,
    })
    .then(function (index) {
      var htmlElement = `<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper guestswiper">`;
      for (var i = 1; i <= totalguest; i++) {
        htmlElement +=
          `<button class="guestui" onClick="selectGuest('` +
          i +
          `', '` +
          save +
          `', '` +
          nextPayload +
          `');"> ` +
          i +
          `</button>`;
      }
      htmlElement += `</div></div>`;

      var result = {
        add: {
          type: "html",
          content: htmlElement,
        },
      };
      newMessage(result);
    });
}

jQuery(".guestui").click(function () {
  jQuery(this).addClass("active");
});

function selectGuest(value, save, nextPayload) {
  if (save) saveValue(save, value);
  if (nextPayload) offline(nextPayload, value);
}

function success(id) {
  if (document.getElementById(id).value === "") {
    document.getElementById(`submit${id}`).disabled = true;
  } else {
    document.getElementById(`submit${id}`).disabled = false;
  }
}

function constructElement(params) {
  homeBot.message
    .add({
      delay: 1500,
      loading: true,
      content: `On a scale of 1 to 5, 1 being the least and 5 being the most, how much do you spend on the following?`,
    })
    .then(function (index) {
      homeBot.message
        .add({
          delay: 1000,
          loading: true,
          content: params.legend,
        })
        .then(function (index) {
          // console.log("Params: ", params);
          let elementType = params.sub_type;
          let nextPayload = params.nextpaylod;
          let persist = params.persist;
          let save = params.save;

          switch (elementType) {
            case "range":
              // console.log("Range type");
              let rangeElement = `<div style="text-align:center;"><span id=output${params.range.id}></span></div><label>${params.range.min}</label><input type="range" id=${params.range.id} min=${params.range.min} max=${params.range.max} step=${params.range.step} oninput="showVal('${this.value}', '${params.range.id}')" onchange="showVal('${this.value}', '${params.range.id}')"/><label>${params.range.max}</label>&nbsp<input type="submit" value="ok" onclick="getRangeValue('${params.range.id}', '${nextPayload}', '${persist}', '${save}')" style="position: relative; top: -7px;"/>`;
              var result = {
                add: {
                  type: "html",
                  content: rangeElement,
                },
              };
              newMessage(result);
              break;
              deafult: console.log(`Default executing`);
              break;
          }
        });
    });
}

function showVal(val, id) {
  $(`#output${id}`).text(document.getElementById(id).value);
}

function getRangeValue(
  id,
  nextPayload,
  persist,
  save,
  validate,
  minTime,
  maxTime
) {
  let rangeValue = document.getElementById(id).value;
  if (!rangeValue || rangeValue === "") {
    return app.dialog.alert("Please provide a valid input.");
  }
  let bookingTime = rangeValue.split("T")[1];
  let bookingDate = rangeValue.split("T")[0];

  let evalMinTime = minTime && minTime.split(":");
  let evalMaxTime = maxTime && maxTime.split(":");

  const minTimeAmPm = parseInt(minTime) > 12 ? "PM" : "AM";
  const maxTimeAmPm = parseInt(maxTime) > 12 ? "PM" : "AM";

  let constructMinTime = `${
    parseInt(minTime) > 12
      ? parseInt(minTime) - 12 < 10
        ? "0" + (parseInt(minTime) - 12)
        : parseInt(minTime) - 12
      : parseInt(minTime)
  }:`;

  constructMinTime +=
    evalMinTime && evalMinTime[1] > 0 ? evalMinTime[1] : "00 ";
  constructMinTime += minTimeAmPm;

  let constructMaxTime = `${
    parseInt(maxTime) > 12
      ? parseInt(maxTime) - 12 < 10
        ? "0" + (parseInt(maxTime) - 12)
        : parseInt(maxTime) - 12
      : parseInt(maxTime)
  }:`;

  constructMaxTime +=
    evalMaxTime && evalMaxTime[1] > 0 ? evalMaxTime[1] : "00 ";
  constructMaxTime += maxTimeAmPm;

  let time = `${
    new Date().getHours() < 9
      ? "0" + new Date().getHours()
      : new Date().getHours()
  }.${
    new Date().getMinutes() < 9
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes()
  }`;
  let today = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1 < 9
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1
  }-${
    new Date().getDate() < 9 ? "0" + new Date().getDate() : new Date().getDate()
  }`;

  bookingTime = `${bookingTime.split(":")[0]}.${bookingTime.split(":")[1]}`;

  if (
    validate &&
    today === bookingDate &&
    parseFloat(bookingTime) <= parseFloat(time)
  ) {
    return app.dialog.alert(
      `Please select a future date, as we do not have a time machine yet ;)`,
      localStorage.getItem(`brandName`)
    );
  }

  if (
    (validate && parseInt(bookingTime) < parseInt(minTime)) ||
    parseInt(bookingTime) > parseInt(maxTime)
  ) {
    return app.dialog.alert(
      `Please book your table between ${constructMinTime} and ${constructMaxTime} time.`
    );
  }

  if (save) saveValue(save, rangeValue);
  if (persist) savePersistData(save, rangeValue);
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: `You have selected ${rangeValue}.`,
    })
    .then(function (index) {
      offline(nextPayload, rangeValue);
    });
}

function placePicker(params) {
  let type = params.sub_type;
  let nextPayload = params.nextpaylod;
  let persist = params.persist;
  let save = params.save;
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: params.legend,
    })
    .then(function (index) {
      //let htmlElement = `<input type=${type} id=${params.text.id} required onchange="success('${params.text.id}')" />&nbsp<input type="submit" id='submit${params.text.id}' value="ok" onclick="getRangeValue('${params.text.id}', '${nextPayload}', '${persist}', '${save}')" style="position: relative;" disabled/>`;
      let htmlElement = `<input 
        type=${type} 
        oninput="triggerGeoCode('${params.text.id}')" 
        onchange="triggerGeoCode('${params.text.id}')" 
        id=${params.text.id} 
        placeholder="Your address please" 
        required="required" 
        class="botui-actions-text-input"
        > <button 
          type="submit" 
          onclick="getUserAddress('${params.text.id}', '${nextPayload}', '${persist}', '${save}')" 
          class="botui-actions-buttons-button"
          ><span>OK</span>
        </button>`;
      var result = {
        add: {
          type: "html",
          content: htmlElement,
        },
      };
      newMessage(result);
    });
}

function userRequest(reqId, status, mobile) {
  jQuery.ajax({
    url: "https://chatappapi.server.scvpl.in/update/user/request",
    // url: "http://localhost:3001/update/user/request",
    type: "POST",
    data: { requestID: reqId, status: status, mobile: mobile },
    success: function (data) {
      console.log(data);
      if (data.error) {
        app.dialog.alert("There is some error! Please try again later.");
      } else {
        if (status == "rejected" || status == "approved") {
          app.dialog.alert(
            "We will inform the concerned user about this deision."
          );
          jQuery("#request_approved_" + reqId).hide();
          jQuery("#request_rejected_" + reqId).hide();
        } else if (status == "cancelled") {
          app.dialog.alert("You have cancelled your request");
          jQuery("#request_" + status + "_" + reqId).hide();
        }
      }
    },
  });
}

function getUserAddress(id, nextPayload, persist, save) {
  // console.log("Params value on getUserAddress function.", id, nextPayload);
  // console.log("Address is: ", document.getElementById(id).value);
  let rangeValue = document.getElementById(id).value;
  if (!rangeValue || rangeValue === "") {
    return app.dialog.alert("Please provide a valid input.");
  }

  var geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: rangeValue }, function (results, status) {
    // console.log(`Geo code results: `, JSON.stringify(results));
    // console.log(`Geo code status : `, JSON.stringify(status));
    if (status == google.maps.GeocoderStatus.OK) {
      var latitude = results[0].geometry.location.lat();
      var longitude = results[0].geometry.location.lng();
      saveValue("lat", latitude);
      saveValue("long", longitude);
      results[0].address_components.map((value, index) => {
        // console.log(value);
        // console.log(index);
        if (value.types[0] == "administrative_area_level_3")
          saveValue("city", value.long_name);
        if (value.types[0] == "administrative_area_level_2")
          saveValue("district", value.long_name);
        if (value.types[0] == "administrative_area_level_1")
          saveValue("state", value.long_name);
        if (value.types[0] == "country") saveValue("country", value.long_name);
        if (value.types[0] == "postal_code")
          saveValue("pinCode", value.long_name);
      });
      // console.log(latitude, longitude);
    }
  });
  if (save) saveValue(save, rangeValue);
  if (persist) savePersistData(save, rangeValue);
  homeBot.message
    .add({
      delay: 1000,
      loading: true,
      content: `You have selected ${rangeValue}.`,
    })
    .then(function (index) {
      offline(nextPayload, rangeValue);
      // $(`input#${id}`).html('');
      // $(`input#${id}`).val('');
      document.getElementById(id).remove();
    });
}

function triggerGeoCode(id) {
  $(`input#${id}`).geocomplete({ details: `#${id}` });
}

function verifyYourself() {
  let promiseOne = new Promise((resolve, reject) => {
    mainView.router.back();
    resolve("Success");
  });
  promiseOne.then((value) => {
    // console.log("On previous page", value);
    // console.log(location.href);
    setTimeout(() => {
      // console.log(location.href);
      location.reload("/");
    }, 1000);
  });
}

function logout() {  
  FIREBASE.auth()
  .signOut()
  .then(() =>{
    Parse.User.logOut().then(() => {
      const currentUser = Parse.User.current();
      console.log(`User after logout.`, currentUser);
    }); 
    localStorage.clear();
    location.reload("/");    
  })
  .catch( (error) => {
    // An error happened.
    console.error("Failed to sign out user: " + error);
  });
}

$$(document).click(`.buttorders`, (e) => {
  // jQuery('.orders-list').on('click', '.buttorders', function (e) {
  var data = jQuery(e.target).data("butt");
  var butt1 = data.replace(/'/g, '"');
  let butt = JSON.parse(butt1);
  var payload = butt;
  var pay_ref = jQuery(e.target).data("pay");
  var price = jQuery(e.target).data("price");
  var oid = jQuery(e.target).data("oid");
  if (butt.cart.discount) {
    var newValue = parseInt(butt.cart.total) + parseInt(butt.cart.discount);
    butt.cart.total = newValue.toString();
    // console.log(butt);
    let tmp = JSON.stringify(butt);
    data = tmp.replace(/"/g, "'");
    // console.log(data);
    price =
      parseInt(jQuery(e.target).data("price")) + parseInt(butt.cart.discount);
  }
  // var event = new CustomEvent('buy', { detail: butt });
  // window.parent.document.dispatchEvent(event);
  // localStorage.setItem('payload-'+payload, payload);
  var products = butt.products;
  var building = "";
  building = butt.bdata.building;
  var finaladdress = "";
  finaladdress = butt.bdata.finaladdress;
  var pay_online = pay_ref
    ? ""
    : '<a class="button button-large button-fill pay" data-butt="' +
      data +
      '" data-oid="' +
      oid +
      '" data-price="' +
      price +
      '" style="width:100%;margin-top:10px;background:#4267b2;box-shadow:none;">Pay Online</a>';
  var payStat = pay_ref ? "Done" : "Pending";
  var address =
    '<p style="margin:6px;">Building: ' +
    building +
    '</p><p style="margin:6px;">Complete: ' +
    finaladdress +
    '</p><p style="margin:6px;">Sub total: ' +
    butt.cart.sub +
    '</p><p style="margin:6px;">Tax: ' +
    butt.cart.tax +
    "</p>" +
    '<p style="margin:6px;">Shipping: ' +
    butt.cart.ship +
    '</p><p style="margin:6px;">Packaging: ' +
    butt.cart.packaging +
    '</p><p style="margin:6px;">Total: ' +
    butt.cart.total +
    '</p><p style="margin:6px;">Payment Status: ' +
    payStat +
    "</p>";
  var productdetail = "";
  var j = 0;
  var dynamicPopup = "";
  // console.log(`Butt :`,butt);
  // console.log(`Products: `,products);
  // console.log(`No. of products: `,products.length);

  products.forEach((product) => {
    var nsku = product.sku;
    nsku = nsku.split("userdata-" + butt.bdata.brandid + "-");
    nsku = nsku[0];
    var attr = "";
    if (product.attribute) {
      attr = product.attribute;
    }
    productdetail +=
      "<li>" +
      '<a href="#" class="item-link item-content">' +
      '<div class="item-inner">' +
      '<div class="item-title"  style="white-space: initial;">' +
      '<div class="item-header">Product: ' +
      nsku +
      "</div>" +
      '<div style="font-size:15px;">|' +
      product.title +
      "|" +
      attr +
      "</div>" +
      '<div class="item-footer"><p>' +
      product.desc +
      '</p><p style="margin:5px 0px;">Price: ' +
      product.price +
      " &emsp;&emsp; Qty: " +
      product.qty +
      "</p></div>" +
      "</div>" +
      '<div class="item-after">Total: ' +
      product.total +
      "</div>" +
      "</div>" +
      "</a>" +
      "</li>";
    // '<p>SKU:  '+nsku+'</p><p>Title:  '+product.title+'</p><p>'+product.description+'</p><p>'+product.attribute+'</p><p> Quantity: '+product.qty+'</p><p> Price: '+product.price+'</p><p> Total: '+product.total+'</p>';
    j++;
  });
  // console.log("j"+j+" products "+products.length);
  if (products.length == j) {
    var sheetContent = "";
    jQuery(".myorder-sheet").empty();
    sheetContent =
      '<div class="toolbar">' +
      '<div class="toolbar-inner">' +
      '<div class="left"></div>' +
      '<div class="right">' +
      '<a class="link sheet-close" style="margin-right:20px;">Close</a>' +
      "</div>" +
      "</div>" +
      "</div>" +
      '<div class="sheet-modal-inner" align="center">' +
      '<div class="page-content" style="padding-top:10px;">' +
      '<div class="padding-horizontal padding-bottom" style="min-width:380px;">' +
      '<a class="button button-large button-fill repeat" data-butt="' +
      data +
      '" style="width:100%;background:#4267b2;box-shadow:none;">Repeat Order</a>' +
      pay_online +
      '<div align="left" class="list" style="margin:15px 0px;"><ul>' +
      productdetail +
      "</ul></div>" +
      '<div align="left" style="padding:10px;">' +
      address +
      "</div>" +
      "</div></div>";
    jQuery(".myorder-sheet").append(sheetContent);
    app.sheet.close(".myorder-sheet");
    // dynamicPopup.open(false);
    setTimeout(() => {
      var newsheet = app.sheet.create({
        el: ".myorder-sheet",
        swipeToClose: false,
        swipeToStep: false,
        backdrop: false,
        closeByBackdropClick: false,
        animate: false,
      });
      newsheet.open();
    }, 500);
  }
  // Events also can be assigned on instance later
  // dynamicPopup.on('close', function (sheet) {
  //   setTimeout(() => {
  //     app.sheet.destroy(dynamicPopup);
  //   }, 1000);
  // });
  // Create Popup with swipe to close
  var swipeToClosePopup = app.popup.create({
    el: ".popup-swipe-to-close",
    swipeToClose: true,
  });
  // Create Popup with swipe to close handler
  var swipeToClosePopup = app.popup.create({
    el: ".popup-swipe-to-close-handler",
    swipeToClose: true,
    swipeHandler: ".my-swipe-to-close-handler",
  });
});

$$(document).click(`.repeat`, (e) => {
  // jQuery('body').on('click', '.repeat', function (e) {
  console.log("Repeat Click");
  var data1 = jQuery(e.target).data("butt");
  var butt1 = data1.replace(/'/g, '"');
  butt1 = JSON.parse(butt1);
  // console.log(`Butt1, inside repeat:`, butt1);
  if (window.location.ancestorOrigins[0] == "file://") {
    var domain = "*";
    window.parent.postMessage({ type: "checkout", detail: [butt1] }, domain);
  } else {
    var event = new CustomEvent("checkout", { detail: [butt1] });
    window.parent.document.dispatchEvent(event);
  }
  mainView.router.back();
  app.sheet.close();
  // jQuery( this ).off( e );
});

$$(document).click(`.pay`, (e) => {
  // jQuery('body').on('click', '.pay', function (e) {
  var data1 = jQuery(e.target).data("butt");
  var butt1 = data1.replace(/'/g, '"');
  butt1 = JSON.parse(butt1);
  // console.log(`Butt1, inside pay:`, butt1);
  var pay_price = jQuery(e.target).data("price");
  var pay_oid = jQuery(e.target).data("oid");
  localStorage.setItem("feed_orderid", pay_oid);
  localStorage.setItem("pay_orderid", pay_oid);
  localStorage.setItem("pay_amount", pay_price);
  if (window.location.ancestorOrigins[0] == "file://") {
    var domain = "*";
    window.parent.postMessage(
      {
        type: "payment",
        pay_oid: pay_oid,
        pay_price: pay_price,
        detail: [butt1],
      },
      domain
    );
  } else {
    var event = new CustomEvent("payment", { detail: [butt1] });
    window.parent.document.dispatchEvent(event);
  }
  mainView.router.back();
  app.sheet.close();
});

$$(document).click(`.checkout`, (e) => {
  // jQuery(document).on("click", ".checkout", function() {
  var taxRate = 0.05;
  var shippingRate = 15.0;
  var currency = "INR";
  var fadeTime = 300;
  var newVal = [];
  var vmn = localStorage.getItem("vmn");
  var name = localStorage.getItem("name");
  var packaging_charge = 0;
  var delivery_charge = 0;
  var taxes = 0;
  var coupon_code = "";
  var subtotal = 0;
  var prodArray = [];
  var brandID = localStorage.getItem("cartbid");
  // console.log("Checkout Click : "+ brandID);
  jQuery.getJSON(
    "https://parseapi.server.scvpl.in/get/branddetails?brandid=" + brandID,
    (jsondata) => {
      // console.log(jsondata);
      app.preloader.hide();
      var njson = jsondata[0];
      // console.log('njson : ', JSON.stringify(njson));
      packaging_charge = parseFloat(njson.packaging_charge);
      delivery_charge = parseFloat(njson.delivery_charge);
      // shippingRate = delivery_charge;
      shippingRate =
        localStorage.getItem("userdata-" + brandID + "-checkloc") == "pickup"
          ? 0
          : delivery_charge;
      packaging_charge =
        localStorage.getItem("userdata-" + brandID + "-checkloc") == "pickup"
          ? 0
          : packaging_charge;
      taxes = njson.taxes;
      taxRate = taxes;
      currency = njson.currency;
      let upi = njson.upi;
      /* Sum up row totals */
      jQuery(".product").each(function (key, value) {
        // console.log('product each: '+jQuery(this).children(".product-price").text());
        var prodimg = jQuery(this)
          .children(".product-image")
          .children()
          .attr("src");
        var prodSKU = jQuery(this)
          .children(".product-details")
          .children(".product-title")
          .data("sku");
        var prodTitle = jQuery(this)
          .children(".product-details")
          .children(".product-title")
          .text();
        var prodDesc = jQuery(this)
          .children(".product-details")
          .children(".product-description")
          .text();
        var prodAttr = jQuery(this)
          .children(".product-details")
          .children(".product-attribute")
          .text();
        var prodPrice = jQuery(this).children(".product-price").text();
        var prodQty = jQuery(this)
          .children(".product-quantity")
          .children()
          .val();
        var prodTotal = jQuery(this).children(".product-line-price").text();
        var prodData = {
          img: prodimg,
          sku: prodSKU,
          title: prodTitle,
          desc: prodDesc,
          attribute: prodAttr,
          price: prodPrice,
          qty: prodQty,
          total: prodTotal,
        };
        prodArray.push(prodData);
        subtotal += parseFloat(
          jQuery(this).children(".product-line-price").text()
        );
      });
      var tax = subtotal * taxRate;
      var shipping = subtotal > 0 ? shippingRate : 0;
      var total = subtotal + tax + shipping;
      subtotal = jQuery("#cart-subtotal").html();
      tax = jQuery("#cart-tax").html();
      shipping = jQuery("#cart-shipping").html();
      packaging_charge = jQuery("#cart-packaging").html();
      total = jQuery("#cart-total").html();
      var discount = jQuery("#cart-discount").html();
      if (discount) {
        discount = discount;
      } else {
        discount = "";
        coupon_code = "";
      }
      var cartdata = {
        sub: subtotal,
        tax: tax,
        ship: shipping,
        packaging: packaging_charge,
        total: total,
        coupon: coupon_code,
        discount: discount,
      };
      var building =
        localStorage.getItem(`userdata-${brandID}-checkloc`) &&
        localStorage.getItem(`userdata-${brandID}-checkloc`) === `pickup`
          ? `At Store`
          : localStorage.getItem("p-" + brandID + "-building");
      var finaladdress =
        localStorage.getItem(`userdata-${brandID}-checkloc`) &&
        localStorage.getItem(`userdata-${brandID}-checkloc`) === `pickup`
          ? `At Store`
          : localStorage.getItem("p-" + brandID + "-finaladdress");
      // console.log(`BUilding ${building} and Final Address is ${finaladdress}`);
      var bdata = {
        brandid: brandID,
        userid: vmn,
        name: name,
        building: building,
        finaladdress: finaladdress,
      };
      // console.log('bdata : ', JSON.stringify(bdata));
      setTimeout(() => {
        // console.log(prodArray);
        if (prodArray.length > 0) {
          var finaldata = [
            { products: prodArray, cart: cartdata, bdata: bdata },
          ];
          if (window.location.ancestorOrigins[0] == "file://") {
            var domain = "*";
            window.parent.postMessage(
              { type: "checkout", detail: finaldata },
              domain
            );
            clearproduct(brandID);
          } else {
            var event = new CustomEvent("checkout", { detail: finaldata });
            window.parent.document.dispatchEvent(event);
            clearproduct(brandID);
          }
          jQuery(".orders > .product").each(function (key, value) {
            var delbutton = jQuery(this).children(".product-removal");
            removeItem(delbutton);
          });
          dynamicrecalculateCart();
          // sheet.close();
          var pageName = "'checkout'";
          jQuery(".orders").empty();
          if (app.sheet.get(".sheet-modal").open) {
            app.sheet.close();
          }
          jQuery(".orders").html(
            '<h2 align="center">Thanks for your order.</h2><br>' +
              '<div align="center"><img class="botui-message-content-image" src="https://www.socialrecharge.com/chat/icon/generic/OrderSuccessful.gif" alt="delivery" style="border:#eee 20px solid;border-radius:10px;"></div><br>' +
              '<button align="center" class="checkoutclose" onClick="openIndexPage(' +
              pageName +
              ');"><span>Close</span></button>'
          );
          localStorage.removeItem("cartbid");
          jQuery(".cartlink").css("visibility", "hidden");
        } else {
          sheet.close();
          jQuery(".orders").empty();
          jQuery(".orders").html(
            '<h2 align="center">Please add products to cart!</h2><br><div align="center" class="animated bounce slow infinite"><img src="img/down.png"></div>'
          );
        }
      }, 1000);
    }
  );
  function dynamicrecalculateCart() {
    // console.log('dynamicrecalculateCart taxRate : '+taxRate);
    var subtotal = 0;
    jQuery(".product").each(function (key, value) {
      subtotal += parseFloat(
        jQuery(this).children(".product-line-price").text()
      );
    });
    var tax = subtotal * taxRate;
    var shipping = subtotal > 0 ? shippingRate : 0;
    var total = subtotal + tax + shipping + packaging_charge;
    jQuery(".totals-value").fadeOut(fadeTime, function () {
      jQuery("#cart-subtotal").html(subtotal.toFixed(2));
      jQuery("#cart-tax").html(tax.toFixed(2));
      jQuery("#cart-shipping").html(shipping.toFixed(2));
      jQuery("#cart-packaging").html(parseFloat(packaging_charge).toFixed(2));
      jQuery("#cart-total").html(parseFloat(total).toFixed(2));
      jQuery("#check-total").html(parseFloat(total).toFixed(2));
      if (total == 0) {
        jQuery(".checkout").fadeOut(fadeTime);
      } else {
        jQuery(".checkout").fadeIn(fadeTime);
      }
      jQuery(".totals-value").fadeIn(fadeTime);
    });
  }
});

const addNewPost = (brandid) => {
  try {
    // console.log(`Inside add new post method. Brand id is ${brandid}`);
    let postType = "text";
    let haveToOpenPostDataPopup = false;
    let postTypeFormPopup = app.popup
      .create({
        content:
          '<div class="popup">' +
          '<div class="block-title">Post type</div>' +
          '<div class="block">' +
          '<div class="list" id="postTypeForm">' +
          "<ul>" +
          '<li class="item-content item-input item-input-outline">' +
          '<div class="item-media">' +
          '<i class="icon f7-icons">square_favorites_alt</i>' +
          "</div>" +
          '<div class="item-inner">' +
          '<div class="item-title item-floating-label">Title</div>' +
          '<div class="item-input-wrap">' +
          '<select name="postType">' +
          '<option value="text" selected>Text</option>' +
          '<option value="image">Image</option>' +
          '<option value="video">video</option>' +
          "</select>" +
          '<span class="input-clear-button"></span>' +
          "</div>" +
          "</div>" +
          "</li>" +
          "</ul>" +
          '<p class="row">' +
          '<button class="col button button-outline postTypeSubmit">Ok</button>' +
          "</p>" +
          '<p class="row">' +
          '<button class="col button button-outline popup-close" style="--f7-button-border-color: red;">Close</button>' +
          "</p>" +
          "</div>" +
          "</div>" +
          "</div>",
      })
      .open();

    $$(`.postTypeSubmit`).on(`click`, () => {
      postTypeFormPopup.close();
      const postTypeFormData = app.form.convertToData(`#postTypeForm`);
      // console.log(`Data: `, postTypeFormData);
      postType = postTypeFormData.postType;
      // console.log(`Post type is ${postType}`);
      haveToOpenPostDataPopup = true;
      // postFormPopup.open();
    });

    postTypeFormPopup.on(`closed`, function (popup) {
      // console.log(`Post type popup closed.`);
      // console.log(`Post type is ${postType}`);
      if (!haveToOpenPostDataPopup) return;
      let postFormPopup = app.popup
        .create({
          content:
            postType == "text"
              ? '<div class="popup">' +
                '<div class="page-content">' +
                '<div class="block-title block-title-large center">Post Data</div>' +
                '<div class="block">' +
                // '<p><a href="#" class="link popup-close">Close me</a></p>' +
                '<div class="list no-hairlines-md" id="postFormData">' +
                "<ul>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">t_bubble</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Title</div>' +
                '<div class="item-input-wrap">' +
                '<input type="text" name="title" placeholder="Post title" required validate>' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">square_pencil</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Description</div>' +
                '<div class="item-input-wrap">' +
                '<textarea class="resizable" name="description" required validate></textarea>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">textformat_123</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Point</div>' +
                '<div class="item-input-wrap">' +
                '<input type="number" name="point" placeholder="Point">' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">checkmark_alt_circle</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Disable/Enable</div>' +
                '<div class="item-after">' +
                '<label class="toggle toggle-init">' +
                '<input type="checkbox" name="toggle" value="yes"><i class="toggle-icon"></i>' +
                "</label>" +
                "<div>" +
                "</div>" +
                "</li>" +
                "</ul>" +
                '<p class="row">' +
                '<button class="col button button-outline postFormDataSubmit">Submit</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline resetFormData" style="--f7-button-border-color: orange;">Reset</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline popup-close" style="--f7-button-border-color: red;">Close</button>' +
                "</p>" +
                "</div>" +
                "</div>" +
                "</div>" +
                "</div>"
              : postType == "image"
              ? '<div class="popup">' +
                '<div class="page-content">' +
                '<div class="block-title block-title-large center">Post Data</div>' +
                '<div class="block">' +
                '<div class="list no-hairlines-md" id="postFormData">' +
                "<ul>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">t_bubble</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Title</div>' +
                '<div class="item-input-wrap">' +
                '<input type="text" name="title" placeholder="Post title" required validate>' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">square_pencil</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Description</div>' +
                '<div class="item-input-wrap">' +
                '<textarea class="resizable" name="description" required validate></textarea>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">photo</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Post Image URL</div>' +
                '<div class="item-input-wrap">' +
                '<input type="url" name="postImage" placeholder="Post Image URL" required validate></input>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">textformat_123</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Point</div>' +
                '<div class="item-input-wrap">' +
                '<input type="number" name="point" placeholder="Point">' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">checkmark_alt_circle</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Disable/Enable</div>' +
                '<div class="item-after">' +
                '<label class="toggle toggle-init">' +
                '<input type="checkbox" name="toggle" value="yes"><i class="toggle-icon"></i>' +
                "</label>" +
                "<div>" +
                "</div>" +
                "</li>" +
                "</ul>" +
                '<p class="row">' +
                '<button class="col button button-outline postFormDataSubmit">Submit</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline resetFormData" style="--f7-button-border-color: orange;">Reset</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline popup-close" style="--f7-button-border-color: red;">Close</button>' +
                "</p>" +
                "</div>" +
                "</div>" +
                "</div>" +
                "</div>"
              : '<div class="popup">' +
                '<div class="page-content">' +
                '<div class="block-title block-title-large center">Post Data</div>' +
                '<div class="block">' +
                '<div class="list no-hairlines-md" id="postFormData">' +
                "<ul>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">t_bubble</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Title</div>' +
                '<div class="item-input-wrap">' +
                '<input type="text" name="title" placeholder="Post title" required validate>' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">square_pencil</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Description</div>' +
                '<div class="item-input-wrap">' +
                '<textarea class="resizable" name="description" required validate></textarea>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">film</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Post Video URL</div>' +
                '<div class="item-input-wrap">' +
                '<input type="url" name="postVideo" placeholder="Post Video URL" required validate></input>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">textformat_123</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Point</div>' +
                '<div class="item-input-wrap">' +
                '<input type="number" name="point" placeholder="Point">' +
                '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                '<li class="item-content item-input item-input-outline">' +
                '<div class="item-media">' +
                '<i class="icon f7-icons">checkmark_alt_circle</i>' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">Disable/Enable</div>' +
                '<div class="item-after">' +
                '<label class="toggle toggle-init">' +
                '<input type="checkbox" name="toggle" value="yes"><i class="toggle-icon"></i>' +
                "</label>" +
                "<div>" +
                "</div>" +
                "</li>" +
                "</ul>" +
                '<p class="row">' +
                '<button class="col button button-outline postFormDataSubmit">Submit</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline resetFormData" style="--f7-button-border-color: orange;">Reset</button>' +
                "</p>" +
                '<p class="row">' +
                '<button class="col button button-outline popup-close" style="--f7-button-border-color: red;">Close</button>' +
                "</p>" +
                "</div>" +
                "</div>" +
                "</div>" +
                "</div>",
          on: {
            open: function (popup) {
              // console.log(`Popup open`);
            },
            opened: function (popup) {
              // console.log(`Popup opened`);

              $$(`.postFormDataSubmit`).on(`click`, () => {
                app.preloader.show();
                // console.log("Submit click", postType);
                let finalPostFormData = app.form.convertToData("#postFormData");
                // console.log(`Post form data : `, finalPostFormData);
                if (
                  finalPostFormData.title &&
                  finalPostFormData.description &&
                  (postType === "image"
                    ? finalPostFormData.postImage
                    : (postType === "video" && finalPostFormData.postVideo) ||
                      true)
                ) {
                  let sendData = {
                    title: finalPostFormData.title,
                    description: finalPostFormData.description,
                    type: postType,
                    point: finalPostFormData.point || 0,
                    enable: finalPostFormData.toggle[0],
                    imageURL:
                      postType === `image` ? finalPostFormData.postImage : null,
                    videoURL:
                      postType === `video` ? finalPostFormData.postVideo : null,
                    brandID: brandid,
                    brandCode: localStorage.getItem(`brandCode`),
                  };
                  app.request.promise
                    .post(
                      `https://chatappapi.server.scvpl.in/set/brand/post`,
                      sendData
                    )
                    .then(function (res) {
                      app.preloader.hide();
                      // console.log(`Response data : `, res.data.data);
                      // console.log("Load was performed");
                      let data = JSON.parse(res.data)["data"];
                      // console.log(`data:`,data);
                      // if (!data.enable) return;
                      if (data.enable && postType === `text`) {
                        $$(`.list .brandPost`).prepend(`<li>
                          <div class="card card-outline">
                            <div class="card-header">${data.title}</div>
                            <div class="card-content card-content-padding">${data.description}</div>
                            <div class="card-footer"><a href="#" class="link">Like</a><a href="#" class="link">Comment</a
                            ><a href="#" class="link">Share</a></div>
                          </div>
                          </li>`);
                      } else if (data.enable && postType === `image`) {
                        $$(`.list .brandPost`)
                          .prepend(`<li><div class="card demo-facebook-card"><div class="card-header">${data.title}</div>              
                          <div class="card-content card-content-padding">
                            <img
                              src=${data.imageURL}
                              width="100%"
                            />
                            <p class="likes">Likes: 112 &nbsp;&nbsp; Comments: 43</p>
                            <p>${data.description}</p>
                          </div>
                          <div class="card-footer">
                            <a href="#" class="link">Like</a><a href="#" class="link">Comment</a
                            ><a href="#" class="link">Share</a>
                          </div>
                        </div></li>`);
                      } else if (data.enable) {
                        //FOR VIDEO POST TYPE
                        var id =
                          data.videoURL.includes("?v=") ||
                          data.videoURL.includes("&v=")
                            ? data.videoURL.split("v=")[1].split(/[\",\&]+/)[0]
                            : data.videoURL.includes("youtu.be")
                            ? data.videoURL
                                .split("youtu.be/")[1]
                                .split(/[\",\&]+/)[0]
                            : data.videoURL.includes("//www.youtube.com/embed/")
                            ? data.videoURL
                                .split(".com/embed/")[1]
                                .split(/[\",\&]+/)[0]
                            : data.videoURL.includes(
                                "//www.facebook.com/plugins/video.php?href="
                              )
                            ? data.videoURL.split(/[ ]+/)[0]
                            : "";

                        var videoUrl = id.includes(
                          "//www.facebook.com/plugins/video.php?href="
                        )
                          ? "https:" +
                            sanitizeHtml(id.split(/[\"]+/)[0]).replace(
                              /\\/g,
                              ""
                            )
                          : "https://www.youtube.com/embed/" +
                            id +
                            "?controls=0";

                        $$(`.list .brandPost`)
                          .prepend(`<li><div class="card demo-facebook-card"><div class="card-header">${data.title}</div>              
                          <div class="card-content card-content-padding">
                          <iframe class="videoIframe"
                            width="100%"
                            height="100%" 
                            style="border:none; overflow:hidden" 
                            frameborder="0" 
                            allowTransparency="true" 
                            allowFullScreen="true" 
                            src=${videoUrl}
                          ></iframe>
                            <p class="likes">Likes: 112 &nbsp;&nbsp; Comments: 43</p>
                            <p>${data.description}</p>
                          </div>
                          <div class="card-footer">
                            <a href="#" class="link">Like</a><a href="#" class="link">Comment</a
                            ><a href="#" class="link">Share</a>
                          </div>
                        </div></li>`);
                      }
                      postFormPopup.close(); //Close FORM DATA POPUP, WHEN DATA SUBMITTED SUCCESSFULLY
                    })
                    .catch(function (err) {
                      app.preloader.hide();
                      // console.log(`Error xhr:`, err.xhr);
                      // console.log(`Error status:`, err.status);
                      // console.log(`Error message:`, err.message);
                    });
                } else {
                  app.preloader.hide();
                  app.dialog.alert(
                    "Please provide essential data for the post."
                  );
                }
              });

              $$(`.resetFormData`).on(`click`, () => {
                let formData = {
                  title: "",
                  description: "",
                  point: "",
                  postImage: "",
                  postVideo: "",
                  toggle: ["no"],
                };
                app.form.fillFromData("#postFormData", formData);
              });
            },
          },
        })
        .open();
    });
  } catch (error) {
    console.error(
      `Error in addNewPost method. Error is ${JSON.stringify(error)}`
    );
  }
};

const reactionButtonTextClick = (id, brandID) => {
  try {
    // console.log(`Inside reaction button text click. ID is ${id}`);
    if ($(`.reaction-btn-text-${id}`).hasClass("active")) {
      // console.log(`Has active class`);
      let reaction = $(`.reaction-btn-text-${id}`).text();
      // console.log(`reaction: ${reaction}`);
      // Sending Ajax request in handler page to perform the database operations
      $(`.reaction-btn-text-${id}`)
        .text("Like")
        .removeClass()
        .addClass(`reaction-btn-text reaction-btn-text-${id}`);
      $(`.reaction-btn-emo-${id}`)
        .removeClass()
        .addClass(`reaction-btn-emo reaction-btn-emo-${id}`)
        .addClass("like-btn-default");
      $(`.like-emo-${id}`).html(
        `<span class='like-btn-like like-btn-like-${id}'></span>`
      );
      // const brandID = localStorage.getItem(`brandid`);
      const postData = {
        brandID: brandID,
        brandCode: localStorage.getItem(`brandCode`),
        mobile: localStorage.getItem(`vmn`),
        postID: id,
        reaction: reaction,
        type: `remove`,
      };
      const url = `https://chatappapi.server.scvpl.in/set/postreaction`;
      // const url = `https://cd59d10b1039.ngrok.io/set/postreaction`;
      // const url = `http://localhost:3001/set/postreaction`;
      app.request.promise
        .post(url, postData)
        .then((res) => {
          // console.log(`Post response: ${JSON.stringify(res)}`);
          const data = JSON.parse(res.data)['data'];          
          let reactionCount = data.like ? data.like : 0 + data.love ? data.love : 0 + data.angry ? data.angry : 0 + data.haha ? data.haha : 0 + data.wow ? data.wow : 0 + data.sad ? data.sad : 0;
          $(`.like-details-${id}`).html(`${reactionCount} others`);

          if (JSON.parse(res.data)["point"]) {
            app.toast
              .create({
                icon:
                  app.theme === "ios"
                    ? '<div class="item-media"><i class="icon f7-icons if-not-md">minus</i></div>'
                    : '<div class="item-media"><i class="icon material-icons md-only">minus</i></div>',
                text: `${JSON.parse(res.data)["point"]} debited.`,
                position: "center",
                closeTimeout: 2000,
              })
              .open();
          }
        })
        .catch((err) => {
          console.error(
            `Error in post request and error is ${JSON.stringify(err)}`
          );
        });
    } else {
      // console.log(`Not active`);
    }
  } catch (error) {
    console.error(
      `Error in reactionButtonTextClick module. Error is ${JSON.stringify(
        error
      )}`
    );
  }
};

const emojiClick = (id, reaction, brandID) => {
  try {
    // console.log(`Id: ${id}`);
    // console.log(`Reaction: ${reaction}`);
    let previousReaction = $(`.reaction-btn-text-${id}`).text();
    // console.log(`Previous reaction: ${previousReaction}`);
    // const brandID = localStorage.getItem(`brandid`);
    const postData = {
      brandID: brandID,
      brandCode: localStorage.getItem(`brandCode`),
      mobile: localStorage.getItem(`vmn`),
      postID: id,
      reaction: reaction,
      type: `add`,
      previousReaction: previousReaction,
    };
    const url = `https://chatappapi.server.scvpl.in/set/postreaction`;
    // const url = `https://cd59d10b1039.ngrok.io/set/postreaction`;
    // const url = `http://localhost:3001/set/postreaction`;
    app.request.promise
      .post(url, postData)
      .then((res) => {
        // console.log(`Post response: ${JSON.stringify(res)}`);
        const data = JSON.parse(res.data)['data'];        
        let reactionCount = data.like ? data.like : 0 + data.love ? data.love : 0 + data.angry ? data.angry : 0 + data.haha ? data.haha : 0 + data.wow ? data.wow : 0 + data.sad ? data.sad : 0;
        $(`.like-details-${id}`).html(`You and ${parseInt(reactionCount) - 1 } others`);

        if (JSON.parse(res.data)["point"]) {
          app.toast
            .create({
              icon:
                app.theme === "ios"
                  ? '<div class="item-media"><i class="icon f7-icons if-not-md">star</i></div>'
                  : '<div class="item-media"><i class="icon material-icons md-only">star</i></div>',
              text: `You have earned ${JSON.parse(res.data)["point"]} points.`,
              position: "center",
              closeTimeout: 2000,
            })
            .open();
        }
      })
      .catch((err) => {
        console.error(
          `Error in post request and error is ${JSON.stringify(err)}`
        );
      });
    $(`.reaction-btn-emo-${id}`)
      .removeClass()
      .addClass(`reaction-btn-emo reaction-btn-emo-${id}`)
      .addClass("like-btn-" + reaction.toLowerCase());
    $(`.reaction-btn-text-${id}`)
      .text(reaction)
      .removeClass()
      .addClass(`reaction-btn-text reaction-btn-text-${id}`)
      .addClass("reaction-btn-text-" + reaction.toLowerCase())
      .addClass("active");

    if (reaction == "Like")
      $(`.like-emo-${id}`).html(
        `<span class=like-btn-like like-btn-like-${id}></span>`
      );
    else
      $(`.like-emo-${id}`).html(
        `<span class=like-btn-like like-btn-like-${id}></span><span class=like-btn-${reaction.toLowerCase()}></span>`
      );
  } catch (error) {
    console.error(
      `Error in emojiClick module. Error is ${JSON.stringify(error)}`
    );
  }
};

const shareClick = (id) => {
  try {
    // console.log(`ID, on share click: ${id}`);
    const data = $$(`.share-${id}`).dataset();
    // console.log(`Data: ${JSON.stringify(data)}`);
    // console.log(`Title: ${data.title}`)

    if (navigator.share) {
      navigator
        .share({
          title: data.title,
          text: data.description,
          url: data.image,
        })
        .then(() => {
          var ogmessage = {
            add: {
              content: "Thank you for sharing 👍",
            },
          };
          newMessage(ogmessage);
        })
        .catch((err) => {
          console.error("Share failed:", err.message);
        });
    } else {
      // share message further..
      app.sheet
        .create({
          content:
            `
          <div class="sheet-modal" style="height:160px;">
            <div class="toolbar">
              <div class="toolbar-inner">
                <div class="left"></div>
                <div class="right">
                  <a class="link sheet-close">Close</a>
                </div>
              </div>
            </div>
            <div class="sheet-modal-inner">
              <div class="block" style="margin-top:15px;">
                <div class="botui-message-content html"><span>Please click on the following links to Share:
                <a class="link external" onclick="this.target='_blank'" href="https://facebook.com/sharer/sharer.php?u=` +
            encodeURI(data.image) +
            `"><i class="fa fa-facebook-square"></i>&nbsp;Facebook</a>&nbsp;
                <a class="link external" onclick="this.target='_blank'" href="https://twitter.com/intent/tweet/?text=` +
            encodeURI(data.title) +
            ";url=" +
            encodeURI(data.image) +
            `"><i class="fa fa-twitter-square"></i>&nbsp;Twitter</a>&nbsp;
                <a class="link external" onclick="this.target='_blank'" href="https://www.linkedin.com/sharing/share-offsite/?url=` +
            encodeURI(data.image) +
            `"><i class="fa fa-linkedin-square"></i>&nbsp;Linkedin</a>&nbsp;
                <a class="link external" onclick="this.target='_blank'" href="https://wa.me/?text=` +
            encodeURI(data.title + " " + data.image) +
            `"><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</a></span><div style="overflow: auto !important;"></div></div>
              </div>
            </div>
          </div>
        `,
          on: {
            opened: function (sheet) {
              // console.log('Sheet opened');
            },
          },
        })
        .open();
      // var ogmessage = {
      //   add: {
      //     type: "html",
      //     content: `Please click on the following links to Share: <a class="link external" target="_blank" href="https://facebook.com/sharer/sharer.php?u=` +
      //     encodeURI( data.image ) +
      //     `"><i class="fa fa-facebook-square"></i> Facebook</a>&nbsp;<a class="link external" target="_blank" href="https://twitter.com/intent/tweet/?text=` +
      //     encodeURI( data.title ) + ";url=" + encodeURI( data.image ) +
      //     `"><i class="fa fa-twitter-square"></i> Twitter</a>&nbsp;<a class="link external" target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=` +
      //     encodeURI( data.image ) +
      //     `"><i class="fa fa-linkedin-square"></i> Linkedin</a>&nbsp;<a class="link external" target="_blank" href="https://wa.me/?text=` +
      //     encodeURI( data.title + " " + data.image ) +
      //     `"><i class="fa fa-whatsapp"></i> Whatsapp</a>`,
      //   },
      // };
      // newMessage(ogmessage);
    }
  } catch (error) {
    console.error(
      `Error in shareClick module. Error is ${JSON.stringify(error)}`
    );
  }
};

function validateUserAction(messages, validateData) {
  try {
    // console.log(`Messages: ${JSON.stringify(messages)} and Validate Data: ${JSON.stringify(validateData)}`);
    if (validateData.legend) {
      homeBot.message
        .add({
          content: validateData.legend,
        })
        .then((index) => {
          switch (validateData.action) {
            case "input":
              homeBot.action
                .text({
                  action: {
                    sub_type: validateData.type ? validateData.type : "text",
                    placeholder: validateData.placeholder
                      ? validateData.placeholder
                      : "Enter here",
                  },
                })
                .then((res) => {
                  // console.log(`Value : ${res.value}`);
                  // console.log(`Defined criteria: ${validateData.validation}`);
                  let val = res.value;
                  let criteria = validateData.validation;
                  let validationCriteria = `${val + criteria}`;
                  // console.log(`Criteria: ${validationCriteria}`)
                  if (parseInt(validationCriteria)) {
                    // console.log(`Meet the criteria`)
                    if (validateData.save)
                      saveValue(validateData.save, res.value);
                    if (validateData.nextPayload)
                      offline(validateData.nextPayload, res.value);
                  } else {
                    // console.log(`Wrong input`);
                    app.dialog.alert(validateData.errorMessage);
                    validateUserAction(messages, validateData);
                  }
                });
              break;
            case "button":
              homeBot.action
                .button({
                  action: [
                    {
                      text: validateData.text,
                      value: validateData.value,
                      icon: validateData.icon ? validateData.icon : ``,
                    },
                  ],
                })
                .then((res) => {
                  if (validateData.save)
                    saveValue(validateData.save, res.value);
                  if (validateData.nextPayload)
                    offline(validateData.nextPayload, res.value);
                });
              break;
            case "select":
              homeBot.action
                .select({
                  action: {
                    placeholder: validateData.placeholder
                      ? validateData.placeholder
                      : `Please select`,
                    value: validateData.value ? validateData.value : ``,
                    searchselect: validateData.search
                      ? validateData.search
                      : false,
                    label: validateData.label ? validateData.label : `text`,
                    multipleselect: validateData.multipleSelect
                      ? validateData.multipleSelect
                      : false,
                    options: validateData.options, //MUST BE SUPPLIED WITH ARRAY OF OBJECT [{value: 1, text: 'One'},{value: 2, text: 'Two'}].
                    button: {
                      icon: `check`,
                      label: `Ok`,
                    },
                  },
                })
                .then((res) => {
                  if (validateData.save)
                    saveValue(validateData.save, res.value);
                  if (validateData.nextPayload)
                    offline(validateData.nextPayload, res.value);
                });
              break;
            default:
            // console.log(`Default is executing.`);
          }
        });
    }
  } catch (error) {
    console.error(
      `Error in validateUserAction module. Error is ${JSON.stringify(error)}`
    );
  }
}

function fetchNews(params) {
  const url = `https://chatappapi.server.scvpl.in/fetch/news`;
  let lang = localStorage.getItem(
    `userdata-${localStorage.getItem(`brandid`)}-language`
  );
  let topic = localStorage.getItem(`topic`);
  let search = localStorage.getItem(
    `userdata-${localStorage.getItem(`brandid`)}-search`
  );
  let mobile = localStorage.getItem(`vmn`);
  const data = {
    language: lang,
    topic: topic,
    search: search,
    mobile: mobile,
  };

  app.request.promise
    .post(url, data)
    .then(async (res) => {
      const element =
        JSON.parse(res.data).length > 0 ? JSON.parse(res.data) : "";
      const result = await nativeCarousel(element, "", "");
      // console.log(`Result: ${result}`);
      offline("searchAndCategory", "");
    })
    .catch(function (err) {
      console.log(`Error XHR:`, err.xhr);
      console.log(`Error status:`, err.status);
      console.log(`Error message:`, err.message);
    });
}

function unsubscribeFromNotification(messages) {
  try {
    const messaging = FIREBASE.messaging();
    messaging
      .getToken({
        vapidKey:
          "BKrjiH7SY-WQR78OWs0Cv1RKfZo81sFoRm0v-t1PB2midMYQNkWvGSMoDDoOOOvzyCOObeotFTaVWoNeGEm-gBg",
      })
      .then((currentToken) => {
        console.log(`Current token : ${currentToken}`);
        if (currentToken) {          
          const bid = localStorage.getItem(`brandid`);
          const FCMTopicSubscriptionURL = `https://newsum-fcm.server.scvpl.in/unsubscribe/from/topics`;
          app.request.promise
            .postJSON(FCMTopicSubscriptionURL, {
              tag: bid,
              user_token: currentToken,
            })
            .then(function (res) {
              console.log(
                `FCM success token un-subscription response:`,
                res.data
              );
            })
            .catch(function (err) {
              console.error(`FCM topic un-subscription error xhr:`, err.xhr);
              console.error(
                `FCM topic un-subscription error status:`,
                err.status
              );
              console.error(
                `FCM topic un-subscription error message:`,
                err.message
              );
            });
          offline("menu", "");
        } else {
          let message = {
            add: {
              content: `You are not subscribe for the notification.`,
            },
          };
          newMessage(message);          
        }
      })
      .catch((err) => {
        console.error(
          `Error in fetching FCM token. Error is ${JSON.stringify(err)}`
        );
      });
  } catch (err) {
    console.error(
      `Error in unsubscribeFromNotification function. Error is ${JSON.stringify(
        err
      )}`
    );
  }
}

// console.log(app.device);
if (
  app.device.android ||
  app.device.androidChrome ||
  app.device.desktop ||
  app.device.windows ||
  app.device.firefox
) {
  var messaging = FIREBASE.messaging();
  messaging.onMessage((notification) => {
    // console.log(`New message received : ${JSON.stringify(notification)}`);
    const notificationTitle = notification.notification.icon;
    const notificationOptions = {
      body: notification.notification.body,
      icon: notification.notification.icon,
    };
    var images = "";
    if (notification.notification.icon) {
      images = " ![product image](" + notification.notification.icon + ")";
    }
    var pushmessage = "";
    // console.log(`Data Payload: ${notification.data.payload}`);
    if (notification.data.payload === `meetingRequest`) {
      return app.dialog.confirm(
        notification.notification.body,
        "ChatApp",
        () => {
          mainView.router.navigate({
            name: `Requests`,
            path: `/requests/`,
          });
        },
        () => {}
      );
    }

    if (notification.embed_url) {
      pushmessage = {
        add: {
          type: "embed",
          content: notification.embed_url,
        },
      };
      newMessage(pushmessage);
    } else if (notification.payload) {
      showNotification(notification.payload, 64);
    } else {
      var pushmessage = {
        add: {
          content:
            notification.notification.title +
            ": " +
            notification.notification.body +
            images,
        },
      };
      newMessage(pushmessage);
    }

    var bid = brandID;
    // console.log("Bid " + bid);
    // storeUrl.close();
  });
}

export const constructRequestDescription = (result) => {
  try {
    return new Promise((resolve, reject) => {
      // console.log(`Result:`,JSON.stringify(result));
      let detailedDescription = "";

      if (result.get("personal")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Personal meeting:</span> ',
          result.get("personal"),
          "<br>"
        );
      }

      if (result.get("businessOp")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Business opportunity description:</span> ',
          result.get("businessOp"),
          "<br>"
        );
      }

      if (result.get("others")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Others description:</span> ',
          result.get("others"),
          "<br>"
        );
      }

      if (result.get("callBack")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">CallBack description:</span> ',
          result.get("callBack"),
          "<br>"
        );
      }

      if (result.get("businessMeet")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Business meeting description:</span> ',
          result.get("businessMeet"),
          "<br>"
        );
      }

      if (result.get("personalMeet")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Personal meeting description:</span> ',
          result.get("personalMeet"),
          "<br>"
        );
      }

      if (result.get("meetingTime")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Meeting time:</span> ',
          result.get("meetingTime"),
          "<br>"
        );
      }

      if (result.get("meetingDate")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Meeting date:</span> ',
          result.get("meetingDate"),
          "<br>"
        );
      }

      if (result.get("askForAJob")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Asking for a job:</span> ',
          result.get("askForAJob"),
          "<br>"
        );
      }

      if (result.get("socialMeeting")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Social meeting description:</span> ',
          result.get("socialMeeting"),
          "<br>"
        );
      }

      if (result.get("meetingLocation")) {
        let latLong = result.get(`latlong`)
          ? result.get(`latlong`)
          : `${
              result.get("meetingLocation").split(",")[
                result.get("meetingLocation").split(",").length - 2
              ]
            },${
              result.get("meetingLocation").split(",")[
                result.get("meetingLocation").split(",").length - 1
              ]
            }`;
        latLong = latLong.trim();

        const locationUrl = `https://www.google.com/maps/search/?api=1&query=${latLong}`;

        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Meeting location:</span> ',
          result.get("meetingLocation"),
          "<br>"
        );
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Location map URL:</span> ',
          `<a href=${locationUrl} alt="direction" class="external" target="blank">Direction</a>`,
          "<br>"
        );
      }

      if (result.get("onlineMeetingURL")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Online meeting URL:</span> ',
          `<a href=${result.get(
            "onlineMeetingURL"
          )} alt="meetingURL" class="external" target="blank">Meeting URL</a>`,
          "<br>"
        );
      }

      if (result.get("offerAJob")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Offering a job:</span> ',
          result.get("offerAJob"),
          "<br>"
        );
      }

      if (result.get("sellProduct")) {
        detailedDescription = detailedDescription.concat(
          '<span style="text-transform: uppercase">Selling product description:</span> ',
          result.get("sellProduct"),
          "<br>"
        );
      }

      if (result.get("status")) {
        detailedDescription = detailedDescription.concat(
          '<strong><span style="text-transform: uppercase">Request status:</span></strong> ',
          `<strong>${result.get("status")}</strong>`,
          "<br>"
        );
      }

      if (detailedDescription.length > 0) {
        resolve(detailedDescription);
      } else {
        reject(new Error("No request decription found."));
      }
    });
  } catch (error) {
    console.error(
      `Error in constructRequestDescription module. Error is`,
      error
    );
  }
};

export const dynamicSocialMediaHandle = (socialmedia) => {
  try{
    
    const remoteUserName = localStorage.getItem(`remoteUserName`);    
    // const URL = `http://localhost:3001/get/user/social/handle`;
    const URL = `https://chatappapi.server.scvpl.in/get/user/social/handle`;
    const DATA = {remoteUser: remoteUserName, socialMedia: socialmedia};
    app.request.promise.json(URL, DATA)
      .then(res => {        
        if (res.data.data && res.data.data.length > 0) {
          console.log(`Inside if`);
          const socialHandleRes = [{
            add: {
              type: "html",
              content: `<a class='botui-message-content-link external' target='_blank' href=${res.data.data}>${socialmedia.toUpperCase()}</a>`
            }
          }, {
            action: {
              button: {
                action: [{
                  text: "Explore",
                  value: "explore"
                }]
              }
            }
          }];
          addmessage(socialHandleRes);
        } else {          
          const socialHandleRes = [{
            add: {              
              content: `Sorry, either ${localStorage.getItem(`remoteUser`)} hasn't ${socialmedia.toUpperCase()} profile or ${localStorage.getItem(`remoteUser`)} hasn't shared with us.`
            }
          }, {
            action: {
              button: {
                action: [{
                  text: "Explore",
                  value: "explore"
                }]
              }
            }
          }];
          addmessage(socialHandleRes);
        }
      }).catch(function (err) {
        console.log(`Error xhr:`,err.xhr)
        console.log(`Error status:`,err.status)
        console.log(`Error message:`,err.message)
      });
  } catch (err) {
    console.error(`Error in dynamicSocialMediaHandle module. Error is ${err}`);
  }
}

window.logout = logout;
window.reactionButtonTextClick = reactionButtonTextClick;
window.emojiClick = emojiClick;
window.shareClick = shareClick;
window.selectDate = selectDate;
window.selectTime = selectTime;
window.selectGuest = selectGuest;
window.cancelReservation = cancelReservation;
window.userRequest = userRequest;
window.onBodyLoad = onBodyLoad;
window.verifyYourself = verifyYourself;
window.selectcountry = selectcountry;

export {
  $$,
  app,
  mainView,
  updateUserProfile,
  afterUserVerification,
  authenticateUser,
  cancelReservation,
  verifyUser,
  takeUserConsent,
  saveUserAndBrand,
  checkUserAuth,
  isUserVerifiedWithPage,
  payviaupi,
  addtohome,
  selectcountry,
  verifymob,
  verifyemail,
  onBodyLoad,
  onBackKeyDown,
  fetchUserLocationWeather,
  fetchTodayHoroscope,
  verifyBotUser,
  openUsername,
  successUser,
  checkAvailability,
  openDatePicker,
  selectDate,
  openTimePicker,
  selectTime,
  openGuestPicker,
  selectGuest,
  success,
  constructElement,
  showVal,
  getRangeValue,
  placePicker,
  userRequest,
  getUserAddress,
  triggerGeoCode,
  verifyYourself,
  logout,
  addNewPost,
  reactionButtonTextClick,
  emojiClick,
  shareClick,
  validateUserAction,
  fetchNews,
  unsubscribeFromNotification,  
};
/*module.exports.app = app;
module.exports.updateUserProfile = updateUserProfile;
module.exports.afterUserVerification = afterUserVerification;
module.exports.authenticateUser = authenticateUser;
module.exports.cancelReservation = cancelReservation;
module.exports.verifyUser = verifyUser;
module.exports.takeUserConsent = takeUserConsent;
module.exports.saveUserAndBrand = saveUserAndBrand;
module.exports.checkUserAuth = checkUserAuth;
module.exports.isUserVerifiedWithPage = isUserVerifiedWithPage;
module.exports.payviaupi = payviaupi;
module.exports.addtohome = addtohome;
module.exports.selectcountry = selectcountry;
module.exports.verifymob = verifymob;
module.exports.verifyemail = verifyemail;
module.exports.onBodyLoad = onBodyLoad;
module.exports.onBackKeyDown = onBackKeyDown;
module.exports.fetchUserLocationWeather = fetchUserLocationWeather;
module.exports.fetchTodayHoroscope = fetchTodayHoroscope;
module.exports.verifyBotUser = verifyBotUser;
module.exports.openUsername = openUsername;
module.exports.successUser = successUser;
module.exports.checkAvailability = checkAvailability;
module.exports.openDatePicker = openDatePicker
module.exports.selectDate = selectDate;
module.exports.openTimePicker = openTimePicker;
module.exports.selectTime = selectTime;
module.exports.openGuestPicker = openGuestPicker
module.exports.selectGuest = selectGuest;
module.exports.success = success;
module.exports.constructElement = constructElement;
module.exports.showVal = showVal;
module.exports.getRangeValue = getRangeValue;
module.exports.placePicker = placePicker;
module.exports.userRequest = userRequest;
module.exports.getUserAddress = getUserAddress;
module.exports.triggerGeoCode = triggerGeoCode;
module.exports.verifyYourself = verifyYourself;
module.exports.logout = logout;
module.exports.addNewPost = addNewPost;
module.exports.reactionButtonTextClick = reactionButtonTextClick;
module.exports.emojiClick = emojiClick;
module.exports.shareClick = shareClick;
module.exports.validateUserAction = validateUserAction;
module.exports.fetchNews = fetchNews;
module.exports.unsubscribeFromNotification = unsubscribeFromNotification;*/
