import { offline, fetchPersistentMenu, processfb, saveValue } from "./sr_x.js";
import firebase from "firebase/app";
import { FIREBASE } from "./firebase";
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

$(document).ready(function () {
  // console.log(`Inside index.js =======>`);
  FIREBASE.auth().onAuthStateChanged(function (user) {
    if (user) {      
      user.displayName &&
        user.displayName !== "undefined" &&
        localStorage.setItem("name", user.displayName) &&
        localStorage.setItem(
          `userdata-${localStorage.getItem(`brandid`)}-name`,
          user.displayName
        );
      user.displayName == null ||
      (user.displayName === "undefined" &&
        localStorage.getItem(`brandid`) &&
        (localStorage.getItem(`name`) ||
          localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
          localStorage.getItem(
            `userdata-${localStorage.getItem(`brandid`)}-name`
          )))
        ? updateUserProfile()
        : `${
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              )) ||
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `userdata-${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              ))
          }`;
      
      user.providerData[0].providerId === "phone" && user.phoneNumber
        ? localStorage.setItem("vmn", user.phoneNumber)
        : user.providerId === "google.com" && user.email
        ? localStorage.setItem("email", user.email)
        : null;
    }
  });

  setTimeout(function () {
    let brandID = localStorage.getItem(`brandid`);
    let isSignedIn = localStorage.getItem(`isSignedIn`);
    let userSignedInData =
      localStorage.getItem("vmn") || localStorage.getItem("email")
        ? localStorage.getItem("vmn") || localStorage.getItem("email")
        : null;
    let userEmail = localStorage.getItem(`${brandID}-email`);

    const pageType = $(".botui-app-container").data("pagetype");
    const fbPageID = $(".botui-app-container").data("pageid");
    brandID = $(".botui-app-container").data("brandid");
    if (pageType === "fb" && fbPageID) {      
      return openPop(fbPageID, "fb", undefined, brandID, undefined);
    }

    var brandCode = $(".botui-app-container").data("code");

    var postId = getUrlParameter("postId");
    if (postId) {
      
      mainView.router.navigate({
        name: `post`,
        path: `/post/:postid`,
        params: { postid: postId },
      });
    } else {
      if (isSignedIn === "true" && userSignedInData) {
        if (localStorage.getItem("name") == "friend") {
          // console.log(localStorage.getItem("name"));
          var newmsg = [
            {
              add: {
                content: `Your name please`,
              },
            },
            {
              action: {
                text: {
                  action: {
                    nextpayload: "explore",
                    placeholder: "Your name please",
                    sub_type: "text",
                  },
                },
              },
              save: "name",
              persist: true,
            },
          ];
          // addmessage(newmsg);
          openPop(brandCode, undefined, undefined, brandID, newmsg);
        } else {
          // offline("exist_start");
          openPop(brandCode, undefined, undefined, brandID, "exist_start");
        }
      } else if (isSignedIn !== "true" && userSignedInData) {
        // offline(`buildYourProfile`);
        openPop(brandCode, undefined, undefined, brandID, "buildYourProfile");
      } else {
        var objId = getUrlParameter("t");
        // console.log(objId);
        if (objId) {
          saveValue("objectID", objId);
          // offline("autoVerifyWithWhatsApp");
          openPop(brandCode, undefined, undefined, brandID, "autoVerifyWithWhatsApp");
        } else {
          // offline("get_started");
          openPop(brandCode, undefined, undefined, brandID, "get_started");
        }
      }
    }
    // handleExternalURLs();
  }, 2500);
});

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
}
